import React, { MouseEventHandler } from 'react'
import ReactDOM from 'react-dom'
import { createRoot } from 'react-dom/client'
import 'virtual:windi.css'
import { QueryClientProvider, QueryClient } from 'react-query'
import axios from 'axios'
import { ErrorBoundary } from 'react-error-boundary'
import { RecoilRoot } from 'recoil'
import Root from './root'
import './i18n'
import { Config } from '../config'
import { PublicClientApplication } from '@azure/msal-browser'
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from '@azure/msal-react'
import { loginRequest, msalConfig } from './msal-config'
import Home from '@/portal/views/home'

//Filepond doesn't include this prop in its TS interface, so we add it through module augmentation
declare module 'filepond' {
  interface FilePondOptions {
    credits?: string
  }
}

axios.defaults.baseURL = Config.ApiBaseUrl
const msalInstance = new PublicClientApplication(msalConfig)
const queryClient = new QueryClient()

function ErrorFallback({
  error,
  resetErrorBoundary,
}: {
  error: Error
  resetErrorBoundary: MouseEventHandler<HTMLButtonElement>
}) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}
const onError = (error: Error) => {
  // Do something with the error
  // E.g. log to an error logging client here
  console.log('error', JSON.stringify(error))
}

const container = document.getElementById('root')
if (!container) {
  throw new Error('No container found')
}
const root = createRoot(container) // createRoot(container!) if you use TypeScript

root.render(
  // <React.StrictMode>
  <ErrorBoundary
    onError={onError}
    FallbackComponent={ErrorFallback}
    onReset={() => {
      // reset the state of your app so the error doesn't happen again
    }}
  >
    <MsalProvider instance={msalInstance}>
      <QueryClientProvider client={queryClient}>
        <RecoilRoot>
          <AuthenticatedTemplate>
            <Root />
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Home />
          </UnauthenticatedTemplate>
        </RecoilRoot>
      </QueryClientProvider>
    </MsalProvider>
  </ErrorBoundary>,
  // </React.StrictMode>,
)
