import { DataType } from '@/portal/views/portfolio-page'
import { subMonths } from 'date-fns'
import generatedData from './data.json'

export const testData: Omit<DataType, 'month' | 'year'>[] = generatedData
  .reverse()
  .map((data) => {
    return {
      fund_data: {
        funds: [
          { label: 'Profitable fund', value: 'PF' },
          { label: 'Other fund', value: 'OF' },
        ],
        fund_stats: [
          {
            name: 'Waarde participaties',
            value: `€ ${new Intl.NumberFormat('nl-NL').format(
              data.waardeParticpaties,
            )}`,
          },
          { name: 'Maandrendement', value: `${data.maandRendement}%` },
          { name: 'Rendement YTD', value: `${data.rendementYTD}%` },
        ],
        total: {
          title: 'Totaal',
          overview: {
            caption: '',
            headers: { name: 'Overzicht', num: 'Vorig', value: 'Huidig' },
            table: [
              {
                name: 'Totale waarde Participaties',
                value: `€ ${new Intl.NumberFormat('nl-NL').format(
                  data.vorigTotaleWaardeParticipaties,
                )}`,
                num: `€ ${new Intl.NumberFormat('nl-NL').format(
                  data.huidigTotaleWaardeParticipaties,
                )}`,
              },
              {
                name: 'Participaties',
                value: `${data.vorigParticipaties}`,
                num: `${data.huidigParticipaties}`,
              },
            ],
          },
        },
        monthly: {
          title: 'Mutaties',
          month_overview: {
            caption: '',
            headers: {
              name: 'Beschrijving',
              num: 'Bedrag',
              value: 'Participaties',
            },
            table: [
              {
                name: 'Storting',
                num: `€ ${new Intl.NumberFormat('nl-NL').format(
                  data.stortingBedrag,
                )}`,
                value: `${data.stortingParticipaties}`,
              },
              {
                name: 'Opname',
                num: `- €${new Intl.NumberFormat('nl-NL').format(
                  data.opnameBedrag,
                )}`,
                value: `- ${data.opnameParticipaties}`,
              },
              {
                name: 'Dividend',
                num: `€ ${new Intl.NumberFormat('nl-NL').format(
                  data.dividend,
                )}`,
                value: '',
              },
            ],
          },
        },
      },
    }
  })

const months = [
  { label: 'januari', value: 1 },
  { label: 'februari', value: 2 },
  { label: 'maart', value: 3 },
  { label: 'april', value: 4 },
  { label: 'mei', value: 5 },
  { label: 'juni', value: 6 },
  { label: 'juli', value: 7 },
  { label: 'augustus', value: 8 },
  { label: 'september', value: 9 },
  { label: 'oktober', value: 10 },
  { label: 'november', value: 11 },
  { label: 'december', value: 12 },
]
export const time = {
  months,
  years: [
    { label: '2023', value: 2023 },
    { label: '2022', value: 2022 },
    { label: '2021', value: 2021 },
  ],
}

const today = new Date()
export const data: DataType[] = testData.map((item, index) => {
  const date = subMonths(today, index + 1)
  const year = date.getFullYear()
  return {
    ...item,
    month: months[date.getMonth()],
    year: { label: year.toString(), value: year },
  }
})
