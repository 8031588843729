import React from 'react'
import './info-page.scss'

type Props = {
  content: string
}

const InfoPageContent: React.FC<Props> = ({ content }) => (
  <div
    className="info-page-content flex flex-col text-justify py-5 rounded-lg"
    dangerouslySetInnerHTML={{ __html: content }}
  ></div>
)

export default InfoPageContent
