import { ColorKey, colorKeys, ThemeConfig } from '@/admin/models/theme'
import isInArray from '@/core/utils/is-in-array'

const setThemeCSS: (themeConfig: ThemeConfig) => void = (
  themeConfig: ThemeConfig,
) => {
  const keysToCssProps: { [key in ColorKey]: string } = {
    primaryColor: '--color-primary',
    secondaryColor: '--color-secondary',
    backgroundColor: '--color-background',
    textColor: '--color-text',
  }

  const setConfigValue = (key: string) => {
    if (!isInArray(key, colorKeys)) return
    const cssProp = keysToCssProps[key]
    const value = themeConfig[key]

    if (value) {
      document.documentElement.style.setProperty(cssProp, `#${value}`)
    } else {
      document.documentElement.style.removeProperty(cssProp)
    }
  }

  Object.keys(themeConfig).forEach(setConfigValue)

  if (themeConfig.backgroundImage) {
    document.documentElement.style.setProperty(
      '--background-image',
      `url('${import.meta.env.VITE_THEME_IMG_DIR?.toString()}/${themeConfig.backgroundImage.toString()}')`,
    )
  } else {
    document.documentElement.style.removeProperty('--background-image')
  }
}
export default setThemeCSS
