import { SelectOptionTypeT } from './onboarding'

export const countries: SelectOptionTypeT[] = [
  { value: 'nl', label: 'countries.nl', label_en: '', riskWeight: 1 },
  { value: 'be', label: 'countries.be', label_en: '', riskWeight: 2 },
  { value: 'lu', label: 'countries.lu', label_en: '', riskWeight: 3 },
]
export const Benelux = ['Benelux']

export const fullListOfCountries: SelectOptionTypeT[] = [
  {
    label: 'Netherlands',
    label_en: 'Netherlands',
    value: 'Netherlands',
  },
  {
    label: 'Afghanistan',
    value: 'Afghanistan',
    label_en: 'Afghanistan',
  },
  {
    label: 'Albania',
    value: 'Albania',
    label_en: 'Albania',
  },
  {
    label: 'Algeria',
    value: 'Algeria',
    label_en: 'Algeria',
  },
  {
    label: 'Andorra',
    value: 'Andorra',
    label_en: 'Andorra',
  },
  {
    label: 'Angola',
    value: 'Angola',
    label_en: 'Angola',
  },
  {
    label: 'Anguilla',
    value: 'Anguilla',
    label_en: 'Anguilla',
  },
  {
    label: 'Antigua & Barbuda',
    value: 'Antigua & Barbuda',
    label_en: 'Antigua & Barbuda',
  },
  {
    label: 'Argentina',
    value: 'Argentina',
    label_en: 'Argentina',
  },
  {
    label: 'Armenia',
    value: 'Armenia',
    label_en: 'Armenia',
  },
  {
    label: 'Australia',
    value: 'Australia',
    label_en: 'Australia',
  },
  {
    label: 'Austria',
    value: 'Austria',
    label_en: 'Austria',
  },
  {
    label: 'Azerbaijan',
    value: 'Azerbaijan',
    label_en: 'Azerbaijan',
  },
  {
    label: 'Bahamas',
    value: 'Bahamas',
    label_en: 'Bahamas',
  },
  {
    label: 'Bahrain',
    value: 'Bahrain',
    label_en: 'Bahrain',
  },
  {
    label: 'Bangladesh',
    value: 'Bangladesh',
    label_en: 'Bangladesh',
  },
  {
    label: 'Barbados',
    value: 'Barbados',
    label_en: 'Barbados',
  },
  {
    label: 'Belarus',
    value: 'Belarus',
    label_en: 'Belarus',
  },
  {
    label: 'Belgium',
    value: 'Belgium',
    label_en: 'Belgium',
  },
  {
    label: 'Belize',
    value: 'Belize',
    label_en: 'Belize',
  },
  {
    label: 'Benin',
    value: 'Benin',
    label_en: 'Benin',
  },
  {
    label: 'Bermuda',
    value: 'Bermuda',
    label_en: 'Bermuda',
  },
  {
    label: 'Bhutan',
    value: 'Bhutan',
    label_en: 'Bhutan',
  },
  {
    label: 'Bolivia',
    value: 'Bolivia',
    label_en: 'Bolivia',
  },
  {
    label: 'Bosnia & Herzegovina',
    value: 'Bosnia & Herzegovina',
    label_en: 'Bosnia & Herzegovina',
  },
  {
    label: 'Botswana',
    value: 'Botswana',
    label_en: 'Botswana',
  },
  {
    label: 'Brazil',
    value: 'Brazil',
    label_en: 'Brazil',
  },
  {
    label: 'Brunei',
    value: 'Brunei',
    label_en: 'Brunei',
  },
  {
    label: 'Bulgaria',
    value: 'Bulgaria',
    label_en: 'Bulgaria',
  },
  {
    label: 'Burkina Faso',
    value: 'Burkina Faso',
    label_en: 'Burkina Faso',
  },
  {
    label: 'Burundi',
    value: 'Burundi',
    label_en: 'Burundi',
  },
  {
    label: 'Cambodia',
    value: 'Cambodia',
    label_en: 'Cambodia',
  },
  {
    label: 'Cameroon',
    value: 'Cameroon',
    label_en: 'Cameroon',
  },
  {
    label: 'Canada',
    value: 'Canada',
    label_en: 'Canada',
  },
  {
    label: 'Cape Verde',
    value: 'Cape Verde',
    label_en: 'Cape Verde',
  },
  {
    label: 'Cayman Islands',
    value: 'Cayman Islands',
    label_en: 'Cayman Islands',
  },
  {
    label: 'Central African Republic',
    value: 'Central African Republic',
    label_en: 'Central African Republic',
  },
  {
    label: 'Chad',
    value: 'Chad',
    label_en: 'Chad',
  },
  {
    label: 'Chile',
    value: 'Chile',
    label_en: 'Chile',
  },
  {
    label: 'China',
    value: 'China',
    label_en: 'China',
  },
  {
    label: 'Colombia',
    value: 'Colombia',
    label_en: 'Colombia',
  },
  {
    label: 'Comoros',
    value: 'Comoros',
    label_en: 'Comoros',
  },
  {
    label: 'Congo',
    value: 'Congo',
    label_en: 'Congo',
  },
  {
    label: 'Congo Democratic Republic',
    value: 'Congo Democratic Republic',
    label_en: 'Congo Democratic Republic',
  },
  {
    label: 'Cook Islands',
    value: 'Cook Islands',
    label_en: 'Cook Islands',
  },
  {
    label: 'Costa Rica',
    value: 'Costa Rica',
    label_en: 'Costa Rica',
  },
  {
    label: 'Croatia',
    value: 'Croatia',
    label_en: 'Croatia',
  },
  {
    label: 'Cuba',
    value: 'Cuba',
    label_en: 'Cuba',
  },
  {
    label: 'Cyprus',
    value: 'Cyprus',
    label_en: 'Cyprus',
  },
  {
    label: 'Czech Republic',
    value: 'Czech Republic',
    label_en: 'Czech Republic',
  },
  {
    label: 'Denmark',
    value: 'Denmark',
    label_en: 'Denmark',
  },
  {
    label: 'Djibouti',
    value: 'Djibouti',
    label_en: 'Djibouti',
  },
  {
    label: 'Dominica',
    value: 'Dominica',
    label_en: 'Dominica',
  },
  {
    label: 'Dominican Republic',
    value: 'Dominican Republic',
    label_en: 'Dominican Republic',
  },
  {
    label: 'East Timor',
    value: 'East Timor',
    label_en: 'East Timor',
  },
  {
    label: 'Ecuador',
    value: 'Ecuador',
    label_en: 'Ecuador',
  },
  {
    label: 'Egypt',
    value: 'Egypt',
    label_en: 'Egypt',
  },
  {
    label: 'El Salvador',
    value: 'El Salvador',
    label_en: 'El Salvador',
  },
  {
    label: 'Equatorial Guinea',
    value: 'Equatorial Guinea',
    label_en: 'Equatorial Guinea',
  },
  {
    label: 'Eritrea',
    value: 'Eritrea',
    label_en: 'Eritrea',
  },
  {
    label: 'Estonia',
    value: 'Estonia',
    label_en: 'Estonia',
  },
  {
    label: 'Ethiopia',
    value: 'Ethiopia',
    label_en: 'Ethiopia',
  },
  {
    label: 'Faeroe Islands',
    value: 'Faeroe Islands',
    label_en: 'Faeroe Islands',
  },
  {
    label: 'Falkland Islands',
    value: 'Falkland Islands',
    label_en: 'Falkland Islands',
  },
  {
    label: 'Fiji',
    value: 'Fiji',
    label_en: 'Fiji',
  },
  {
    label: 'Finland',
    value: 'Finland',
    label_en: 'Finland',
  },
  {
    label: 'France',
    value: 'France',
    label_en: 'France',
  },
  {
    label: 'French Guiana',
    value: 'French Guiana',
    label_en: 'French Guiana',
  },
  {
    label: 'French Polynesia',
    value: 'French Polynesia',
    label_en: 'French Polynesia',
  },
  {
    label: 'Gabon',
    value: 'Gabon',
    label_en: 'Gabon',
  },
  {
    label: 'Gambia',
    value: 'Gambia',
    label_en: 'Gambia',
  },
  {
    label: 'Georgia',
    value: 'Georgia',
    label_en: 'Georgia',
  },
  {
    label: 'Germany',
    value: 'Germany',
    label_en: 'Germany',
  },
  {
    label: 'Ghana',
    value: 'Ghana',
    label_en: 'Ghana',
  },
  {
    label: 'Gibraltar',
    value: 'Gibraltar',
    label_en: 'Gibraltar',
  },
  {
    label: 'Greece',
    value: 'Greece',
    label_en: 'Greece',
  },
  {
    label: 'Greenland',
    value: 'Greenland',
    label_en: 'Greenland',
  },
  {
    label: 'Grenada',
    value: 'Grenada',
    label_en: 'Grenada',
  },
  {
    label: 'Guadeloupe',
    value: 'Guadeloupe',
    label_en: 'Guadeloupe',
  },
  {
    label: 'Guam',
    value: 'Guam',
    label_en: 'Guam',
  },
  {
    label: 'Guatemala',
    value: 'Guatemala',
    label_en: 'Guatemala',
  },
  {
    label: 'Guernsey',
    value: 'Guernsey',
    label_en: 'Guernsey',
  },
  {
    label: 'Guinea',
    value: 'Guinea',
    label_en: 'Guinea',
  },
  {
    label: 'Guinea-Bissau',
    value: 'Guinea-Bissau',
    label_en: 'Guinea-Bissau',
  },
  {
    label: 'Guyana',
    value: 'Guyana',
    label_en: 'Guyana',
  },
  {
    label: 'Haiti',
    value: 'Haiti',
    label_en: 'Haiti',
  },
  {
    label: 'Honduras',
    value: 'Honduras',
    label_en: 'Honduras',
  },
  {
    label: 'Hungary',
    value: 'Hungary',
    label_en: 'Hungary',
  },
  {
    label: 'Iceland',
    value: 'Iceland',
    label_en: 'Iceland',
  },
  {
    label: 'India',
    value: 'India',
    label_en: 'India',
  },
  {
    label: 'Indonesia',
    value: 'Indonesia',
    label_en: 'Indonesia',
  },
  {
    label: 'Iran',
    value: 'Iran',
    label_en: 'Iran',
  },
  {
    label: 'Iraq',
    value: 'Iraq',
    label_en: 'Iraq',
  },
  {
    label: 'Ireland',
    value: 'Ireland',
    label_en: 'Ireland',
  },
  {
    label: 'Israel',
    value: 'Israel',
    label_en: 'Israel',
  },
  {
    label: 'Italy',
    value: 'Italy',
    label_en: 'Italy',
  },
  {
    label: 'Ivory Coast',
    value: 'Ivory Coast',
    label_en: 'Ivory Coast',
  },
  {
    label: 'Jamaica',
    value: 'Jamaica',
    label_en: 'Jamaica',
  },
  {
    label: 'Japan',
    value: 'Japan',
    label_en: 'Japan',
  },
  {
    label: 'Jersey',
    value: 'Jersey',
    label_en: 'Jersey',
  },
  {
    label: 'Jordan',
    value: 'Jordan',
    label_en: 'Jordan',
  },
  {
    label: 'Kazakhstan',
    value: 'Kazakhstan',
    label_en: 'Kazakhstan',
  },
  {
    label: 'Kenya',
    value: 'Kenya',
    label_en: 'Kenya',
  },
  {
    label: 'Kiribati',
    value: 'Kiribati',
    label_en: 'Kiribati',
  },
  {
    label: 'Korea, North',
    value: 'Korea, North',
    label_en: 'Korea, North',
  },
  {
    label: 'Korea, South',
    value: 'Korea, South',
    label_en: 'Korea, South',
  },
  {
    label: 'Kuwait',
    value: 'Kuwait',
    label_en: 'Kuwait',
  },
  {
    label: 'Kyrgyzstan',
    value: 'Kyrgyzstan',
    label_en: 'Kyrgyzstan',
  },
  {
    label: 'Laos',
    value: 'Laos',
    label_en: 'Laos',
  },
  {
    label: 'Latvia',
    value: 'Latvia',
    label_en: 'Latvia',
  },
  {
    label: 'Lebanon',
    value: 'Lebanon',
    label_en: 'Lebanon',
  },
  {
    label: 'Lesotho',
    value: 'Lesotho',
    label_en: 'Lesotho',
  },
  {
    label: 'Liberia',
    value: 'Liberia',
    label_en: 'Liberia',
  },
  {
    label: 'Libya',
    value: 'Libya',
    label_en: 'Libya',
  },
  {
    label: 'Liechtenstein',
    value: 'Liechtenstein',
    label_en: 'Liechtenstein',
  },
  {
    label: 'Lithuania',
    value: 'Lithuania',
    label_en: 'Lithuania',
  },
  {
    label: 'Luxembourg',
    value: 'Luxembourg',
    label_en: 'Luxembourg',
  },
  {
    label: 'Macedonia',
    value: 'Macedonia',
    label_en: 'Macedonia',
  },
  {
    label: 'Madagascar',
    value: 'Madagascar',
    label_en: 'Madagascar',
  },
  {
    label: 'Malawi',
    value: 'Malawi',
    label_en: 'Malawi',
  },
  {
    label: 'Malaysia',
    value: 'Malaysia',
    label_en: 'Malaysia',
  },
  {
    label: 'Maldives',
    value: 'Maldives',
    label_en: 'Maldives',
  },
  {
    label: 'Mali',
    value: 'Mali',
    label_en: 'Mali',
  },
  {
    label: 'Malta',
    value: 'Malta',
    label_en: 'Malta',
  },
  {
    label: 'Man, Isle of',
    value: 'Man, Isle of',
    label_en: 'Man, Isle of',
  },
  {
    label: 'Marshall Islands',
    value: 'Marshall Islands',
    label_en: 'Marshall Islands',
  },
  {
    label: 'Martinique',
    value: 'Martinique',
    label_en: 'Martinique',
  },
  {
    label: 'Mauritania',
    value: 'Mauritania',
    label_en: 'Mauritania',
  },
  {
    label: 'Mauritius',
    value: 'Mauritius',
    label_en: 'Mauritius',
  },
  {
    label: 'Mayotte',
    value: 'Mayotte',
    label_en: 'Mayotte',
  },
  {
    label: 'Mexico',
    value: 'Mexico',
    label_en: 'Mexico',
  },
  {
    label: 'Micronesia',
    value: 'Micronesia',
    label_en: 'Micronesia',
  },
  {
    label: 'Midway Islands',
    value: 'Midway Islands',
    label_en: 'Midway Islands',
  },
  {
    label: 'Moldova',
    value: 'Moldova',
    label_en: 'Moldova',
  },
  {
    label: 'Monaco',
    value: 'Monaco',
    label_en: 'Monaco',
  },
  {
    label: 'Mongolia',
    value: 'Mongolia',
    label_en: 'Mongolia',
  },
  {
    label: 'Montserrat',
    value: 'Montserrat',
    label_en: 'Montserrat',
  },
  {
    label: 'Morocco',
    value: 'Morocco',
    label_en: 'Morocco',
  },
  {
    label: 'Mozambique',
    value: 'Mozambique',
    label_en: 'Mozambique',
  },
  {
    label: 'Myanmar',
    value: 'Myanmar',
    label_en: 'Myanmar',
  },
  {
    label: 'Namibia',
    value: 'Namibia',
    label_en: 'Namibia',
  },
  {
    label: 'Nauru',
    value: 'Nauru',
    label_en: 'Nauru',
  },
  {
    label: 'Nepal',
    value: 'Nepal',
    label_en: 'Nepal',
  },
  {
    label: 'Netherlands Antilles',
    value: 'Netherlands Antilles',
    label_en: 'Netherlands Antilles',
  },
  {
    label: 'New Caledonia',
    value: 'New Caledonia',
    label_en: 'New Caledonia',
  },
  {
    label: 'New Zealand',
    value: 'New Zealand',
    label_en: 'New Zealand',
  },
  {
    label: 'Nicaragua',
    value: 'Nicaragua',
    label_en: 'Nicaragua',
  },
  {
    label: 'Niger',
    value: 'Niger',
    label_en: 'Niger',
  },
  {
    label: 'Nigeria',
    value: 'Nigeria',
    label_en: 'Nigeria',
  },
  {
    label: 'Niue',
    value: 'Niue',
    label_en: 'Niue',
  },
  {
    label: 'Norfolk Island',
    value: 'Norfolk Island',
    label_en: 'Norfolk Island',
  },
  {
    label: 'Northern Mariana Islands',
    value: 'Northern Mariana Islands',
    label_en: 'Northern Mariana Islands',
  },
  {
    label: 'Norway',
    value: 'Norway',
    label_en: 'Norway',
  },
  {
    label: 'Oman',
    value: 'Oman',
    label_en: 'Oman',
  },
  {
    label: 'Pakistan',
    value: 'Pakistan',
    label_en: 'Pakistan',
  },
  {
    label: 'Palau',
    value: 'Palau',
    label_en: 'Palau',
  },
  {
    label: 'Panama',
    value: 'Panama',
    label_en: 'Panama',
  },
  {
    label: 'Papua New Guinea',
    value: 'Papua New Guinea',
    label_en: 'Papua New Guinea',
  },
  {
    label: 'Paraguay',
    value: 'Paraguay',
    label_en: 'Paraguay',
  },
  {
    label: 'Peru',
    value: 'Peru',
    label_en: 'Peru',
  },
  {
    label: 'Philippines',
    value: 'Philippines',
    label_en: 'Philippines',
  },
  {
    label: 'Pitcairn',
    value: 'Pitcairn',
    label_en: 'Pitcairn',
  },
  {
    label: 'Poland',
    value: 'Poland',
    label_en: 'Poland',
  },
  {
    label: 'Portugal',
    value: 'Portugal',
    label_en: 'Portugal',
  },
  {
    label: 'Puerto Rico',
    value: 'Puerto Rico',
    label_en: 'Puerto Rico',
  },
  {
    label: 'Qatar',
    value: 'Qatar',
    label_en: 'Qatar',
  },
  {
    label: 'Reunion',
    value: 'Reunion',
    label_en: 'Reunion',
  },
  {
    label: 'Romania',
    value: 'Romania',
    label_en: 'Romania',
  },
  {
    label: 'Russia',
    value: 'Russia',
    label_en: 'Russia',
  },
  {
    label: 'Rwanda',
    value: 'Rwanda',
    label_en: 'Rwanda',
  },
  {
    label: 'Saint Helena',
    value: 'Saint Helena',
    label_en: 'Saint Helena',
  },
  {
    label: 'Saint Kitts-Nevis',
    value: 'Saint Kitts-Nevis',
    label_en: 'Saint Kitts-Nevis',
  },
  {
    label: 'Saint Lucia',
    value: 'Saint Lucia',
    label_en: 'Saint Lucia',
  },
  {
    label: 'Saint Pierre and Miquelon',
    value: 'Saint Pierre and Miquelon',
    label_en: 'Saint Pierre and Miquelon',
  },
  {
    label: 'Saint Vincent and Grenadines',
    value: 'Saint Vincent and Grenadines',
    label_en: 'Saint Vincent and Grenadines',
  },
  {
    label: 'Samoa, American',
    value: 'Samoa, American',
    label_en: 'Samoa, American',
  },
  {
    label: 'Samoa, Western',
    value: 'Samoa, Western',
    label_en: 'Samoa, Western',
  },
  {
    label: 'San Marino',
    value: 'San Marino',
    label_en: 'San Marino',
  },
  {
    label: 'Sao Tome and Principe',
    value: 'Sao Tome and Principe',
    label_en: 'Sao Tome and Principe',
  },
  {
    label: 'Saudi Arabia',
    value: 'Saudi Arabia',
    label_en: 'Saudi Arabia',
  },
  {
    label: 'Senegal',
    value: 'Senegal',
    label_en: 'Senegal',
  },
  {
    label: 'Serbia',
    value: 'Serbia',
    label_en: 'Serbia',
  },
  {
    label: 'Seychelles',
    value: 'Seychelles',
    label_en: 'Seychelles',
  },
  {
    label: 'Sierra Leone',
    value: 'Sierra Leone',
    label_en: 'Sierra Leone',
  },
  {
    label: 'Singapore',
    value: 'Singapore',
    label_en: 'Singapore',
  },
  {
    label: 'Slovakia',
    value: 'Slovakia',
    label_en: 'Slovakia',
  },
  {
    label: 'Slovenia',
    value: 'Slovenia',
    label_en: 'Slovenia',
  },
  {
    label: 'Solomon Islands',
    value: 'Solomon Islands',
    label_en: 'Solomon Islands',
  },
  {
    label: 'Somalia',
    value: 'Somalia',
    label_en: 'Somalia',
  },
  {
    label: 'South Africa',
    value: 'South Africa',
    label_en: 'South Africa',
  },
  {
    label: 'South Georgia',
    value: 'South Georgia',
    label_en: 'South Georgia',
  },
  {
    label: 'Spain',
    value: 'Spain',
    label_en: 'Spain',
  },
  {
    label: 'Sri Lanka',
    value: 'Sri Lanka',
    label_en: 'Sri Lanka',
  },
  {
    label: 'Sudan',
    value: 'Sudan',
    label_en: 'Sudan',
  },
  {
    label: 'Suriname',
    value: 'Suriname',
    label_en: 'Suriname',
  },
  {
    label: 'Swaziland',
    value: 'Swaziland',
    label_en: 'Swaziland',
  },
  {
    label: 'Sweden',
    value: 'Sweden',
    label_en: 'Sweden',
  },
  {
    label: 'Switzerland',
    value: 'Switzerland',
    label_en: 'Switzerland',
  },
  {
    label: 'Syria',
    value: 'Syria',
    label_en: 'Syria',
  },
  {
    label: 'Taiwan',
    value: 'Taiwan',
    label_en: 'Taiwan',
  },
  {
    label: 'Tajikistan',
    value: 'Tajikistan',
    label_en: 'Tajikistan',
  },
  {
    label: 'Tanzania',
    value: 'Tanzania',
    label_en: 'Tanzania',
  },
  {
    label: 'Thailand',
    value: 'Thailand',
    label_en: 'Thailand',
  },
  {
    label: 'Togo',
    value: 'Togo',
    label_en: 'Togo',
  },
  {
    label: 'Tokelau Islands',
    value: 'Tokelau Islands',
    label_en: 'Tokelau Islands',
  },
  {
    label: 'Tonga',
    value: 'Tonga',
    label_en: 'Tonga',
  },
  {
    label: 'Trinidad and Tobago',
    value: 'Trinidad and Tobago',
    label_en: 'Trinidad and Tobago',
  },
  {
    label: 'Tunisia',
    value: 'Tunisia',
    label_en: 'Tunisia',
  },
  {
    label: 'Turkey',
    value: 'Turkey',
    label_en: 'Turkey',
  },
  {
    label: 'Turkmenistan',
    value: 'Turkmenistan',
    label_en: 'Turkmenistan',
  },
  {
    label: 'Turks and Caicos',
    value: 'Turks and Caicos',
    label_en: 'Turks and Caicos',
  },
  {
    label: 'Tuvalu',
    value: 'Tuvalu',
    label_en: 'Tuvalu',
  },
  {
    label: 'Uganda',
    value: 'Uganda',
    label_en: 'Uganda',
  },
  {
    label: 'Ukraine',
    value: 'Ukraine',
    label_en: 'Ukraine',
  },
  {
    label: 'United Arab Emirates',
    value: 'United Arab Emirates',
    label_en: 'United Arab Emirates',
  },
  {
    label: 'United Kingdom',
    value: 'United Kingdom',
    label_en: 'United Kingdom',
  },
  {
    label: 'Uruguay',
    value: 'Uruguay',
    label_en: 'Uruguay',
  },
  {
    label: 'USA - Alabama',
    value: 'USA - Alabama',
    label_en: 'USA - Alabama',
  },
  {
    label: 'USA - Alaska',
    value: 'USA - Alaska',
    label_en: 'USA - Alaska',
  },
  {
    label: 'USA - Arizona',
    value: 'USA - Arizona',
    label_en: 'USA - Arizona',
  },
  {
    label: 'USA - Arkansas',
    value: 'USA - Arkansas',
    label_en: 'USA - Arkansas',
  },
  {
    label: 'USA - California',
    value: 'USA - California',
    label_en: 'USA - California',
  },
  {
    label: 'USA - Colorado',
    value: 'USA - Colorado',
    label_en: 'USA - Colorado',
  },
  {
    label: 'USA - Connecticut',
    value: 'USA - Connecticut',
    label_en: 'USA - Connecticut',
  },
  {
    label: 'USA - Delaware',
    value: 'USA - Delaware',
    label_en: 'USA - Delaware',
  },
  {
    label: 'USA - Dist of Columbia',
    value: 'USA - Dist of Columbia',
    label_en: 'USA - Dist of Columbia',
  },
  {
    label: 'USA - Florida',
    value: 'USA - Florida',
    label_en: 'USA - Florida',
  },
  {
    label: 'USA - Georgia',
    value: 'USA - Georgia',
    label_en: 'USA - Georgia',
  },
  {
    label: 'USA - Hawaii',
    value: 'USA - Hawaii',
    label_en: 'USA - Hawaii',
  },
  {
    label: 'USA - Idaho',
    value: 'USA - Idaho',
    label_en: 'USA - Idaho',
  },
  {
    label: 'USA - Illinois',
    value: 'USA - Illinois',
    label_en: 'USA - Illinois',
  },
  {
    label: 'USA - Indiana',
    value: 'USA - Indiana',
    label_en: 'USA - Indiana',
  },
  {
    label: 'USA - Iowa',
    value: 'USA - Iowa',
    label_en: 'USA - Iowa',
  },
  {
    label: 'USA - Kansas',
    value: 'USA - Kansas',
    label_en: 'USA - Kansas',
  },
  {
    label: 'USA - Kentucky',
    value: 'USA - Kentucky',
    label_en: 'USA - Kentucky',
  },
  {
    label: 'USA - Louisiana',
    value: 'USA - Louisiana',
    label_en: 'USA - Louisiana',
  },
  {
    label: 'USA - Maine',
    value: 'USA - Maine',
    label_en: 'USA - Maine',
  },
  {
    label: 'USA - Maryland',
    value: 'USA - Maryland',
    label_en: 'USA - Maryland',
  },
  {
    label: 'USA - Massachusetts',
    value: 'USA - Massachusetts',
    label_en: 'USA - Massachusetts',
  },
  {
    label: 'USA - Michigan',
    value: 'USA - Michigan',
    label_en: 'USA - Michigan',
  },
  {
    label: 'USA - Minnesota',
    value: 'USA - Minnesota',
    label_en: 'USA - Minnesota',
  },
  {
    label: 'USA - Mississippi',
    value: 'USA - Mississippi',
    label_en: 'USA - Mississippi',
  },
  {
    label: 'USA - Missouri',
    value: 'USA - Missouri',
    label_en: 'USA - Missouri',
  },
  {
    label: 'USA - Montana',
    value: 'USA - Montana',
    label_en: 'USA - Montana',
  },
  {
    label: 'USA - Nebraska',
    value: 'USA - Nebraska',
    label_en: 'USA - Nebraska',
  },
  {
    label: 'USA - Nevada',
    value: 'USA - Nevada',
    label_en: 'USA - Nevada',
  },
  {
    label: 'USA - New Hampshire',
    value: 'USA - New Hampshire',
    label_en: 'USA - New Hampshire',
  },
  {
    label: 'USA - New Jersey',
    value: 'USA - New Jersey',
    label_en: 'USA - New Jersey',
  },
  {
    label: 'USA - New Mexico',
    value: 'USA - New Mexico',
    label_en: 'USA - New Mexico',
  },
  {
    label: 'USA - New York',
    value: 'USA - New York',
    label_en: 'USA - New York',
  },
  {
    label: 'USA - North Carolina',
    value: 'USA - North Carolina',
    label_en: 'USA - North Carolina',
  },
  {
    label: 'USA - North Dakota',
    value: 'USA - North Dakota',
    label_en: 'USA - North Dakota',
  },
  {
    label: 'USA - Ohio',
    value: 'USA - Ohio',
    label_en: 'USA - Ohio',
  },
  {
    label: 'USA - Oklahoma',
    value: 'USA - Oklahoma',
    label_en: 'USA - Oklahoma',
  },
  {
    label: 'USA - Oregon',
    value: 'USA - Oregon',
    label_en: 'USA - Oregon',
  },
  {
    label: 'USA - Pennsylvania',
    value: 'USA - Pennsylvania',
    label_en: 'USA - Pennsylvania',
  },
  {
    label: 'USA - Rhode Island',
    value: 'USA - Rhode Island',
    label_en: 'USA - Rhode Island',
  },
  {
    label: 'USA - South Carolina',
    value: 'USA - South Carolina',
    label_en: 'USA - South Carolina',
  },
  {
    label: 'USA - South Dakota',
    value: 'USA - South Dakota',
    label_en: 'USA - South Dakota',
  },
  {
    label: 'USA - Tennessee',
    value: 'USA - Tennessee',
    label_en: 'USA - Tennessee',
  },
  {
    label: 'USA - Texas',
    value: 'USA - Texas',
    label_en: 'USA - Texas',
  },
  {
    label: 'USA - Utah',
    value: 'USA - Utah',
    label_en: 'USA - Utah',
  },
  {
    label: 'USA - Vermont',
    value: 'USA - Vermont',
    label_en: 'USA - Vermont',
  },
  {
    label: 'USA - Virginia',
    value: 'USA - Virginia',
    label_en: 'USA - Virginia',
  },
  {
    label: 'USA - Washington',
    value: 'USA - Washington',
    label_en: 'USA - Washington',
  },
  {
    label: 'USA - West Virginia',
    value: 'USA - West Virginia',
    label_en: 'USA - West Virginia',
  },
  {
    label: 'USA - Wisconsin',
    value: 'USA - Wisconsin',
    label_en: 'USA - Wisconsin',
  },
  {
    label: 'USA - Wyoming',
    value: 'USA - Wyoming',
    label_en: 'USA - Wyoming',
  },
  {
    label: 'Uzbekistan',
    value: 'Uzbekistan',
    label_en: 'Uzbekistan',
  },
  {
    label: 'Vanuatu',
    value: 'Vanuatu',
    label_en: 'Vanuatu',
  },
  {
    label: 'Venezuela',
    value: 'Venezuela',
    label_en: 'Venezuela',
  },
  {
    label: 'Vietnam',
    value: 'Vietnam',
    label_en: 'Vietnam',
  },
  {
    label: 'Virgin Islands',
    value: 'Virgin Islands',
    label_en: 'Virgin Islands',
  },
  {
    label: 'Wake Island',
    value: 'Wake Island',
    label_en: 'Wake Island',
  },
  {
    label: 'Wallis and Futuna',
    value: 'Wallis and Futuna',
    label_en: 'Wallis and Futuna',
  },
  {
    label: 'Yemen',
    value: 'Yemen',
    label_en: 'Yemen',
  },
  {
    label: 'Yugoslavia',
    value: 'Yugoslavia',
    label_en: 'Yugoslavia',
  },
  {
    label: 'Zambia',
    value: 'Zambia',
    label_en: 'Zambia',
  },
  {
    label: 'Zimbabwe',
    value: 'Zimbabwe',
    label_en: 'Zimbabwe',
  },
]
