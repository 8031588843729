export enum PaymentStatusCode {
  Success = 190, // (successful)
  Failed = 490, // (failed)
  FailedValidation = 491, // (failed)
  TechnicalError = 492, // (failed)
  Declined = 690, // (failed)
  PendingInput = 790, // (pending)
  PendingProcessing = 791, // (pending)
  WaitingForConsumer = 792, // (pending)
  OnHold = 793, // (pending)
  CanceledByUser = 890, // (cancelled)
  CanceledByMerchant = 891, // (cancelled)
}

export type PaymentStatusType =
  | 'success'
  | 'failed'
  | 'pending-input'
  | 'pending'
  | 'cancelled'

export const getPaymentStatusType = (
  statusCode: PaymentStatusCode,
): PaymentStatusType => {
  switch (statusCode) {
    case PaymentStatusCode.Success:
      return 'success'
    case PaymentStatusCode.Failed:
    case PaymentStatusCode.FailedValidation:
    case PaymentStatusCode.TechnicalError:
    case PaymentStatusCode.Declined:
      return 'failed'
    case PaymentStatusCode.CanceledByMerchant:
    case PaymentStatusCode.CanceledByUser:
      return 'cancelled'
    case PaymentStatusCode.PendingProcessing:
    case PaymentStatusCode.WaitingForConsumer:
    case PaymentStatusCode.OnHold:
      return 'pending'
  }
  return 'pending-input'
}
