import React from 'react'
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  UseFormRegister,
} from 'react-hook-form'
import { countries as untranslatedCountries } from '@/core/models/countries'
import TextInput from '../../components/text-input'
import DateInput from '../../components/date-input'
import FormLayout from '../../components/form-layout'
import styles from './onboarding.module.scss'
import { useTranslation } from 'react-i18next'
import { requiredWithMessage } from '@/common/models/validation'
import Select from '@/portal/components/select-input'
import { regexOnlyLetters, regexTin } from '../../../../core/src/regex'

type Props = {
  register: UseFormRegister<FieldValues>
  errors: FieldErrors
  control?: Control<FieldValues> | undefined
  path: string
}
const PersonalDetails2: React.FC<Props> = ({
  register,
  control,
  errors,
  path,
}) => {
  const { t } = useTranslation()
  const countries = untranslatedCountries.map(({ value, label }) => ({
    value,
    label: t(`${label}`),
  }))

  return (
    <>
      <FormLayout
        dataTestId="DateAndPlaceOfBirth"
        title={t('date_and_place_of_birth')}
      >
        <DateInput
          wrapperClassName={styles.inputWrapperLgSpan4}
          label={t('date') + ' *'}
          {...register(`${path}.date`, requiredWithMessage)}
          errors={errors}
          max="2020-01-01"
          min="1900-01-01"
        />

        <Controller
          name={`${path}.country`}
          control={control}
          render={({ field }) => {
            const { onChange, value, ref: _, ...rest } = field
            return (
              <Select
                {...rest}
                name={`${path}.country`}
                wrapperClassName={styles.inputWrapperLgSpan4}
                label={t('country') + ' *'}
                errors={errors}
                options={countries}
                onChange={(val) => onChange(val)}
                value={value}
              />
            )
          }}
          rules={requiredWithMessage}
        />

        <TextInput
          wrapperClassName={styles.inputWrapperLgSpan4}
          label={t('city') + ' *'}
          {...register(`${path}.city`, {
            required: `${t('required_field')}`,
            pattern: {
              value: regexOnlyLetters,
              message: `${t('invalid_text_format')}`,
            },
          })}
          errors={errors}
        />
      </FormLayout>
      <FormLayout
        dataTestId="CitizenshipAndTaxResidence"
        title={t('citizenship_and_tax_residence')}
      >
        <Controller
          name={`${path}.citizenshipCountry`}
          control={control}
          render={({ field }) => {
            // const { ref: _, ...rest } = field
            const { onChange, value, ref: _, ...rest } = field
            console.log(value)

            return (
              <Select
                {...rest}
                wrapperClassName={styles.inputWrapperLgSpan4}
                label={t('citizenship') + ' *'}
                errors={errors}
                options={countries}
                hasEmptyOption={true}
                onChange={(val) => onChange(val)}
                value={value}
              />
            )
          }}
          rules={requiredWithMessage}
        />

        <Controller
          name={`${path}.taxCountry`}
          control={control}
          render={({ field }) => {
            const { onChange, value, ref: _, ...rest } = field
            console.log(value)
            return (
              <Select
                {...rest}
                name="taxCountry"
                wrapperClassName={styles.inputWrapperLgSpan4}
                label={t('tax_country') + ' *'}
                errors={errors}
                options={countries}
                onChange={(val) => onChange(val)}
                value={value}
              />
            )
          }}
          rules={requiredWithMessage}
        />

        <TextInput
          wrapperClassName={styles.inputWrapperLgSpan4}
          label={t('tin') + ' *'}
          labelClassName={`${styles.labelName}`}
          errors={errors}
          {...register(`${path}.taxIdentificationNumber`, {
            required: `${t('required_field')}`,
            pattern: {
              value: regexTin,
              message: `${t('invalid_tin_number_format')}`,
            },
          })}
        />
      </FormLayout>
    </>
  )
}

export default PersonalDetails2
