import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select from '@/portal/components/select-input'
import { data } from '@/common/services/portfolio-data'
import Table from '../../components/table'
import TableCard from '../../components/table-card'
import { parseInt } from 'lodash'
import { mockData } from './chart-data'
import BasicLineChart, { BasicChartData } from '@/portal/components/basic-chart'
import './portfolio-page.scss'
import nlLocale from 'date-fns/locale/nl'
import { format, subMonths } from 'date-fns'

type ButtonState = 'month' | 'ytd' | 'sixMonths'

export type DataType = {
  month: { label: string; value: number }
  year: { label: string; value: number }
  fund_data: {
    funds: { label: string; value: string }[]
    fund_stats: { name: string; value?: string }[]
    total: {
      title: string
      overview: {
        caption?: string
        headers?: { name: string; num?: string; value: string }
        table?: { name: string; num?: string; value: string }[]
      }
    }
    monthly: {
      title: string
      month_overview: {
        caption?: string
        headers?: { name: string; num?: string; value: string }
        table?: { name: string; num?: string; value: string }[]
      }
    }
  }
}

const PortfolioPage = (): JSX.Element => {
  const { t } = useTranslation()
  const monthsSelectOptions = data.map((item: DataType, index) => {
    return {
      key: [item.year.value, item.month.value].join('-'),
      value: index.toString(),
      label: format(
        new Date(item.year.value, item.month.value - 1, 1),
        'MMM yyyy',
        { locale: nlLocale },
      ),
    }
  })
  const [selectedMonth, setSelectedMonth] = useState<number>(
    new Date().getMonth() + 1,
  )
  const [selectedYear, setSelectedYear] = useState<number>(
    new Date().getFullYear(),
  )
  const [filteredData, setFilteredData] = useState<DataType | undefined>(
    data[data.length - 1],
  )

  const buttonNames: { name: string; value: ButtonState }[] = useMemo(
    () => [
      {
        name: `${t('button_name.month')}`,
        value: 'month',
      },
      {
        name: `${t('button_name.six_months')}`,
        value: 'sixMonths',
      },
      {
        name: `${t('button_name.year_to_date')}`,
        value: 'ytd',
      },
    ],
    [t],
  )
  const [buttonState, setButtonState] = useState<ButtonState>(
    buttonNames[0].value,
  )

  const today = new Date()

  const filteredChartData = (
    chartData: BasicChartData[],
    buttonState: ButtonState,
  ) => {
    return mockData.filter((d) => {
      switch (buttonState) {
        case 'ytd':
          return d.date > today.getFullYear().toString()
        case 'sixMonths':
          return d.date > format(subMonths(today, 6), 'yyyy-MM-01')
        case 'month':
          return d.date > format(subMonths(today, 1), 'yyyy-MM-01')
      }
    })
  }
  const [chartData, setChartData] = useState(
    filteredChartData(mockData, buttonState),
  )

  useEffect(() => {
    console.log({ chartData })
  }, [chartData])

  const handleClick = (event: any) => {
    const value = event.target?.value || 'month'
    switch (value) {
      case 'month':
      case 'sixMonths':
      case 'ytd':
        setButtonState(value)
    }
  }

  useEffect(() => {
    setChartData(filteredChartData(mockData, buttonState))
  }, [buttonState])

  // filters the data in the view
  useEffect(() => {
    const fltrd = data.find(
      (d) => d.month.value === selectedMonth && d.year.value === selectedYear,
    )

    setFilteredData(fltrd)
  }, [selectedMonth, selectedYear])

  return (
    <div className="space-y-4 mb-10 md:pt-4 w-full">
      <h1 className="font-sans font-bold break-normal text-default px-2 mb-4 text-xl mt-12 lg:mt-0 md:text-2xl">
        {t('profitable_fund')}
      </h1>
      <div className="md:flex justify-between">
        <div className="sm:grid md:flex md:flex-row md:gap-4 md:items-center">
          <h3 className="mx-2 sm:my-2 sm:text-left md:text-right ">
            {t('monthly_report') + ':'}
          </h3>
          <Select
            name="select-month"
            options={monthsSelectOptions}
            wrapperClassName="w-32 block"
            onChange={(value) => {
              const index = parseInt(value || '0')
              data[index] && setSelectedMonth(data[index].month.value)
              data[index] && setSelectedYear(data[index].year.value)
            }}
          />
        </div>
      </div>

      {filteredData && (
        <>
          <div className="grid sm:grid-cols-2 md:grid-cols-3 w-full gap-4">
            {filteredData.fund_data.fund_stats.map((d) => (
              <TableCard key={d.name}>
                <div className="font-light text-sm">{d.name}</div>
                <div className="font-bold text-lg">{d.value}</div>
              </TableCard>
            ))}
          </div>

          <div className="grid w-full gap-4">
            <TableCard title={filteredData.fund_data.total.title}>
              <Table data={filteredData.fund_data.total.overview} />
            </TableCard>

            <TableCard title={filteredData.fund_data.monthly.title}>
              <Table data={filteredData.fund_data.monthly.month_overview} />
            </TableCard>
          </div>
          <div className="sm:grid sm:items-start sm:w-full md:flex md:flex-row md:items-stretch md:gap-4">
            <div className="card w-full">
              <span className="relative">
                <div className="font-light text-sm">Current saldo</div>
                <div className="font-bold text-lg">
                  € {new Intl.NumberFormat('nl-NL').format(chartData[0].value)}
                </div>
              </span>
              <BasicLineChart data={chartData} />
              <div className="flex flex-col items-center m-6">
                <nav className="relative inline-flex gap-6">
                  {buttonNames.map((item) => (
                    <div key={item.name}>
                      <input
                        type="radio"
                        onChange={handleClick}
                        id={item.value}
                        value={item.value}
                        className="opacity-0 absolute"
                        name={'graph-buttons'}
                        checked={item.value === buttonState}
                      />
                      <label
                        htmlFor={item.value}
                        className="graph-buttons-group"
                      >
                        <span className="truncate">{item.name}</span>
                      </label>
                    </div>
                  ))}
                </nav>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default PortfolioPage
