import React, { useEffect } from 'react'
import App from './app'
import { FunctionComponent } from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import { ThemeConfig } from '../admin/models/theme'
import { themeState } from '@/common/state/atoms/theme-state'
import setThemeCSS from '@/common/services/set-theme-css'
import { useRecoilState } from 'recoil'
import { useQuery } from 'react-query'
import axios, { AxiosResponse } from 'axios'
import Spinner from '@/common/components/spinner'
import { InvestmentPlanConfig } from '@/core/models/investment-plan-config'
import { CreateInvestmentLevelDto } from '@/core/models/create-investment-level.dto'
import { investmentPlanState } from '@/common/state/atoms/investment-plan-state'
import { investmentLevelState } from '@/common/state/atoms/investment-risk-level-state'

const Root: FunctionComponent = () => {
  const [theme, setTheme] = useRecoilState<ThemeConfig>(themeState)
  const [investmentPlanConfig, setInvestmentPlanConfig] =
    useRecoilState<InvestmentPlanConfig>(investmentPlanState)
  const [riskLevels, setRiskLevels] =
    useRecoilState<CreateInvestmentLevelDto>(investmentLevelState)

  useEffect(() => {
    if (theme) setThemeCSS(theme)
  }, [theme])
  const fetchTheme = useQuery(
    'fetchLatestTheme',
    (): Promise<AxiosResponse<ThemeConfig>> =>
      axios.get('/customization/latest'),
    {
      onSuccess: (data) => {
        const theme = data.data
        setTheme(theme)
      },
    },
  )

  useQuery(
    'fetchInvestmentPlanConfig',
    (): Promise<AxiosResponse<InvestmentPlanConfig>> =>
      axios.get('/investment-plan-config'),
    {
      onSuccess: (data) => {
        const investmentPlanConfig = data.data
        setInvestmentPlanConfig(investmentPlanConfig)
      },
    },
  )

  useQuery(
    'fetchInvestmentLevels',
    (): Promise<AxiosResponse<CreateInvestmentLevelDto>> =>
      axios.get('/investment-levels/latest'),
    {
      onSuccess: (data) => {
        const investmentLevels = data.data
        setRiskLevels(investmentLevels)
      },
    },
  )

  return fetchTheme.isLoading ? (
    <Spinner className="mt-30" />
  ) : (
    <Router>
      <App />
    </Router>
  )
}

export default Root
