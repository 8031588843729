import React from 'react'
import BaseFormComponentProps from '@/common/components/base-form-component-props'
import ValidationError from '@/common/components/forms/validation-error'

type Props = BaseFormComponentProps & {
  disabled?: boolean
  labelClassName?: string
  min?: string
  max?: string
  defaultValue?: Date
}
const DateInput = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      wrapperClassName = '',
      label,
      labelClassName,
      errors,
      onChange,
      onBlur,
      name,
      min,
      max,
      defaultValue,
    }: Props,
    ref,
  ) => (
    <div className={wrapperClassName} data-test-id={name}>
      <label
        htmlFor={name}
        className={`block text-sm font-medium pb-1 ${labelClassName}`}
      >
        {label}
      </label>
      <input
        data-test-id={name}
        type="date"
        onChange={onChange}
        onBlur={onBlur}
        ref={ref}
        id={name}
        name={name}
        className="focus:ring-secondary focus:border-secondary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
        min={min}
        max={max}
        defaultValue={defaultValue?.toISOString().substr(0, 10)}
      />
      {errors && <ValidationError errors={errors} name={name} />}
    </div>
  ),
)

export default DateInput
