import React, { FC, PropsWithChildren } from 'react'
import Button from '@/portal/components/button'
import styles from '@/portal/views/onboarding/onboarding.module.scss'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Logo from '@/common/components/logo'
import { loginRequest, msalConfig, signupRequest } from '@/portal/msal-config'
import { useMsal } from '@azure/msal-react'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type Props = {
  title: string
  route: string
  prevRoute?: string | null
  nextRoute?: string | null
}
const Page: FC<PropsWithChildren<Props>> = ({
  prevRoute,
  nextRoute,
  children,
}) => {
  const history = useNavigate()
  const { instance } = useMsal()
  const { t } = useTranslation()
  const doLogout = () => {
    instance.logout(loginRequest)
    instance.logout(signupRequest)
  }

  return (
    <div className="space-y-6 container max-w-screen-lg min-w-screen-xs">
      <div className="flex flex-row place-content-between">
        <Logo className="lg" />
        <button
          onClick={doLogout}
          className="flex flex-row space-x-2 text-base font-weight-500 font-normal outline-none focus:outline-none"
        >
          <span className="hidden md:block">{t('log_out')}</span>
          <FontAwesomeIcon size="lg" className="mr-2" icon={faSignOutAlt} />
        </button>
      </div>
      <div className="text-sm text-right">{t('required_fields_note')}</div>
      {children}
      <div className="flex justify-end">
        {prevRoute != null && (
          <Button
            dataTestId="Previous"
            type="button"
            onClick={() => history(prevRoute)}
            className={styles.prevButton}
            title={t('previous')}
          />
        )}
        {nextRoute != null && (
          <Button
            dataTestId="Next"
            type="submit"
            className={styles.nextButton}
            title={t('next')}
          />
        )}
      </div>
    </div>
  )
}

export default Page
