import { fullListOfCountries } from './countries'
import { OnboardingFlowT } from './onboarding'

const onboardingFlow: OnboardingFlowT = {
  id: 1,
  pages: [
    {
      type: 'Page',
      name: 'bank-details',
      title: 'Bank Details',
      title_en: 'Bank Details',
      route: '',
      nextRoute: 'personal-details',
      children: [
        {
          type: 'PredefinedContent',
          name: 'bank-details',
        },
      ],
      riskWeight: 0,
    },
    {
      type: 'Page',
      name: 'personal-details',
      title: 'Personal Detail',
      title_en: 'Personal Detail',
      route: 'personal-details',
      prevRoute: '',
      nextRoute: 'personal-details-2',
      children: [
        {
          type: 'PredefinedContent',
          name: 'personal-details',
        },
      ],
    },
    {
      type: 'Page',
      name: 'personal-details-2',
      title: 'Personal Details 2',
      title_en: 'Personal Details 2',
      route: 'personal-details-2',
      prevRoute: 'personal-details',
      nextRoute: 'identification-info',
      children: [
        {
          type: 'PredefinedContent',
          name: 'personal-details-2',
        },
      ],
    },
    {
      type: 'Page',
      title: 'Identification Info',
      title_en: 'Identification Info',
      route: 'identification-info',
      name: 'IdentificationInfo',
      prevRoute: 'personal-details-2',
      nextRoute: 'identification',
      children: [
        {
          type: 'InfoPage',
          name: 'identification-info',
          header: {
            type: 'InfoPageHeader',
            title: 'Identification',
            title_en: 'Identification',
            icon: 'M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2',
            backgroundColor: '#E7EBEF',
            textColor: '#416F7E',
          },
          content: {
            type: 'InfoPageContent',
            content:
              '<h3>Identificeer uzelf</h3><p className="text-sm">Om uw rekening te openen zijn we verplicht een kopie van uw paspoort of identiteitskaart op te vragen en u te identificeren.</p>',
            content_en:
              '<h3>Title</h3><p className="text-sm">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ipsum tincidunt sollicitudin nunc eu dolor. Amet nec lectus lorem dolor. Ac urna at nisl, risus rhoncus. Volutpat ornare neque feugiat sit aenean scelerisque aenean pellentesque aliquam. Nec posuere enim proin massa. Nunc hendrerit urna cursus sit cursus mi.</p>',
          },
        },
      ],
    },
    {
      type: 'Page',
      name: 'identification',
      title: 'Identification',
      title_en: 'Identification',
      route: 'identification',
      prevRoute: 'identification-info',
      nextRoute: 'income-and-capital',
      children: [
        {
          type: 'PredefinedContent',
          name: 'identification',
        },
      ],
    },
    {
      type: 'Page',
      title: 'Income and Capital',
      title_en: 'Income and Capital',
      route: 'income-and-capital',
      name: 'incomeAndCapital',
      prevRoute: 'identification',
      nextRoute: 'occupation',
      riskWeight: 2,
      children: [
        {
          type: 'FormLayout',
          title: 'Uw inkomen en vermogen',
          title_en: 'Income',
          name: 'income',
          riskWeight: 2,
          children: [
            {
              type: 'Select',
              label: 'Wat is de bron van uw inkomen?',
              label_en: 'What is the main source of your income?',
              name: 'mainSourceOfIncome',
              options: [
                {
                  value: 'salary',
                  label: 'Salaris',
                  label_en: 'Salary',
                  riskWeight: 1,
                },
                {
                  value: 'self-employed',
                  label: 'Ondernemer',
                  label_en: 'Self employed',
                  riskWeight: 2,
                },
                {
                  value: 'pension',
                  label: 'Pensioen',
                  label_en: 'Pension',
                  riskWeight: 3,
                },
                {
                  value: 'income-from-capital',
                  label: 'Inkomsten uit vermogen',
                  label_en: 'Income from capital',
                  riskWeight: 4,
                },
                {
                  value: 'social-benefits',
                  label: 'Uitkering',
                  label_en: 'Social benefits',
                  riskWeight: 5,
                },
                {
                  value: 'other-periodical-benefits',
                  label: 'Andere periodieke uitkeringen',
                  label_en: 'Other periodical benefits',
                  riskWeight: 6,
                },
                {
                  value: 'other-income',
                  label: 'Ander inkomen',
                  label_en: 'Other income',
                  riskWeight: 7,
                },
              ],
              validation: {
                required: 'This field is required',
              },
            },
            {
              type: 'Select',
              label: 'Wat is uw bruto jaarinkomen?',
              label_en: 'What is your gross annual income?',
              name: 'grossAnnualIncome',
              options: [
                {
                  value: '1',
                  label: '10,000 - 20,000',
                  label_en: '10,000 - 20,000',
                  riskWeight: 5,
                },
                {
                  value: '2',
                  label: '20,000 - 30,000',
                  label_en: '20,000 - 30,000',
                  riskWeight: 4,
                },
                {
                  value: '3',
                  label: '30,000 - 40,000',
                  label_en: '30,000 - 40,000',
                  riskWeight: 3,
                },
                {
                  value: '4',
                  label: '40,000 - 50,000',
                  label_en: '40,000 - 50,000',
                  riskWeight: 2,
                },
                {
                  value: '5',
                  label: '50,000 - 100,000',
                  label_en: '50,000 - 100,000',
                  riskWeight: 1,
                },
              ],
              validation: {
                required: 'This field is required',
              },
            },
            {
              type: 'RadioGroup',
              label: 'Hoe zeker is uw inkomen?',
              label_en: 'How secure is your income?',
              name: 'howSecureIsYourIncome',
              options: [
                {
                  value: '1',
                  label: 'Elke maand ontvang ik een vast inkomen',
                  label_en: 'Every month I receive a fixed income',
                  riskWeight: 1,
                },
                {
                  value: '2',
                  label: 'Mijn inkomen kan per periode verschillen',
                  label_en: 'My income can vary per period',
                  riskWeight: 2,
                },
                {
                  value: '3',
                  label:
                    'Mijn inkomen kan variëren, maar ik heb een vast minimuminkomen',
                  label_en:
                    'My income can vary, but I have a fixed minimum income',
                  riskWeight: 3,
                },
                {
                  value: '4',
                  label: 'Ik heb geen inkomen',
                  label_en: 'I have no income',
                  riskWeight: 4,
                },
              ],
              validation: {
                required: 'This field is required',
              },
            },
            {
              type: 'RadioGroup',
              label: 'Mijn hoofdinkomen is:',
              label_en: 'My main income is:',
              name: 'myMainIncomeIs',
              options: [
                {
                  value: '1',
                  label: 'Meer dan genoeg om mijn vaste lasten te betalen',
                  label_en: 'More than enough to pay for my fixed costs',
                  riskWeight: 1,
                },
                {
                  value: '2',
                  label: 'Genoeg om mijn vaste lasten te betalen',
                  label_en: 'Enough to pay my fixed costs',
                  riskWeight: 2,
                },
                {
                  value: '3',
                  label: 'Niet genoeg om mijn vaste lasten te betalen',
                  label_en: 'Not enough to pay for my fixed costs',
                  riskWeight: 3,
                },
              ],
              validation: {
                required: 'This field is required',
              },
            },
            {
              type: 'Select',
              label: 'Land',
              label_en: 'Country',
              name: 'longCountryList',
              options: fullListOfCountries,
              validation: {
                required: 'This field is required',
              },
            },
          ],
        },
        {
          type: 'FormLayout',
          title: 'Kapitaal',
          title_en: 'Capital',
          name: 'capital',
          children: [
            {
              type: 'Select',
              label: 'Wat is je vrij beschikbare vermogen?',
              label_en: 'What is your freely available capital?',
              name: 'availableCapital',
              options: [
                {
                  value: '1',
                  label: 'Minder dan 25,000 euro',
                  label_en: 'Less than 25,000 euro',
                  riskWeight: 3,
                },
                {
                  value: '2',
                  label: 'Tussen 25,000 euro en 50,000 euro',
                  label_en: 'Between the 25,000 and 50,000 euro',
                  riskWeight: 2,
                },
                {
                  value: '3',
                  label: 'Meer dan 50.000 euro',
                  label_en: 'More than 50.000 euro',
                  riskWeight: 1,
                },
              ],
              validation: {
                required: 'This field is required',
              },
            },
            {
              type: 'Select',
              label: 'Wat is de bron van uw kapitaal?',
              label_en: 'What is the source of your capital?',
              name: 'sourceOfCapital',
              options: [
                {
                  value: '1',
                  label: 'Loon',
                  label_en: 'Loan',
                  riskWeight: 3,
                },
                {
                  value: '2',
                  label: 'Pensioen',
                  label_en: 'Pension',
                  riskWeight: 2,
                },
                {
                  value: '3',
                  label: 'Uitkering',
                  label_en: 'Social benefit',
                  riskWeight: 1,
                },
                {
                  value: '4',
                  label: 'Inkomen uit eigen onderneming',
                  label_en: 'Income from own company',
                  riskWeight: 1,
                },
                {
                  value: '5',
                  label: 'Inkomen verkoop eigen onderneming',
                  label_en: 'Income due to the sales of own company',
                  riskWeight: 1,
                },
                {
                  value: '6',
                  label: 'Erfenis en/of schenking',
                  label_en: 'Inheritance and/or a donation',
                  riskWeight: 1,
                },
                {
                  value: '7',
                  label: 'Overwaarde huis',
                  label_en: 'Surplus value on a house',
                  riskWeight: 1,
                },
                {
                  value: '8',
                  label: 'Income via the stock exchange or other investment',
                  label_en: 'Inheritance and/or a donation',
                  riskWeight: 1,
                },
                {
                  value: '9',
                  label: 'Investering in onroerend goed',
                  label_en: 'Investment in real estate',
                  riskWeight: 1,
                },
                {
                  value: '10',
                  label: 'Huuropbrengsten',
                  label_en: 'Income from rent',
                  riskWeight: 1,
                },
                {
                  value: '11',
                  label: 'Renteinkomsten uit leningen aan derden',
                  label_en: 'Income via interest on given loans',
                  riskWeight: 1,
                },
                {
                  value: '12',
                  label: 'Anders',
                  label_en: 'Others',
                  riskWeight: 1,
                },
              ],
              validation: {
                required: 'This field is required',
              },
            },
          ],
        },
      ],
    },
    {
      type: 'Page',
      name: 'occupation',
      title: 'Occupation',
      title_en: 'Occupation',
      route: 'occupation',
      prevRoute: 'income-and-capital',
      nextRoute: 'risk-profile',
      children: [
        {
          type: 'PredefinedContent',
          name: 'occupation',
        },
      ],
    },
    {
      type: 'Page',
      title: 'Risk Profile',
      title_en: 'Risk Profile',
      route: 'risk-profile',
      name: 'RiskProfile',
      prevRoute: 'occupation',
      nextRoute: 'investment-plan',
      children: [
        {
          type: 'InfoPage',
          name: 'risk-profile-info',
          header: {
            type: 'InfoPageHeader',
            title: 'Risk Profile',
            title_en: 'RiskProfile',
            icon: 'M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3',
            backgroundColor: '#E7EBEF',
            textColor: '#416F7E',
          },
          content: {
            type: 'InfoPageContent',
            content: `<h3>Neutral Risk Profile</h3>
              <p className="text-sm">What Is Risk Neutral? Risk neutral is a concept used in both game theory studies and in finance. It refers to a mindset where an individual is indifferent to risk when making an investment decision. This mindset is not derived from calculation or rational deduction, but rather from an emotional preference.</p>`,
            content_en: `<h3>Neutral Risk Profile</h3>
              <p className="text-sm">What Is Risk Neutral? Risk neutral is a concept used in both game theory studies and in finance. It refers to a mindset where an individual is indifferent to risk when making an investment decision. This mindset is not derived from calculation or rational deduction, but rather from an emotional preference.</p>`,
          },
        },
      ],
    },
    {
      type: 'Page',
      name: 'investment-plan',
      title: 'Investment Plan',
      title_en: 'Investment Plan',
      route: 'investment-plan',
      prevRoute: 'risk-profile',
      nextRoute: 'first-deposit-info',
      children: [
        {
          type: 'PredefinedContent',
          name: 'investment-plan',
        },
      ],
    },
    {
      type: 'Page',
      title: 'First Deposit Info',
      title_en: 'First Deposit Info',
      route: 'first-deposit-info',
      name: 'FirstDepositInfo',
      prevRoute: 'investment-plan',
      nextRoute: 'payment',
      children: [
        {
          type: 'InfoPage',
          name: 'identification-info',
          header: {
            type: 'InfoPageHeader',
            title: 'First Deposit',
            title_en: 'First Deposit',
            icon: 'M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z',
            backgroundColor: '#E7EBEF',
            textColor: '#416F7E',
          },
          content: {
            type: 'InfoPageContent',
            content:
              '<h3>First Deposit Info</h3><p className="text-sm">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ipsum tincidunt sollicitudin nunc eu dolor. Amet nec lectus lorem dolor. Ac urna at nisl, risus rhoncus. Volutpat ornare neque feugiat sit aenean scelerisque aenean pellentesque aliquam. Nec posuere enim proin massa. Nunc hendrerit urna cursus sit cursus mi.</p>',
            content_en:
              '<h3>First Deposit Info</h3><p className="text-sm">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ipsum tincidunt sollicitudin nunc eu dolor. Amet nec lectus lorem dolor. Ac urna at nisl, risus rhoncus. Volutpat ornare neque feugiat sit aenean scelerisque aenean pellentesque aliquam. Nec posuere enim proin massa. Nunc hendrerit urna cursus sit cursus mi.</p>',
          },
        },
      ],
    },
    {
      type: 'Page',
      name: 'payment',
      title: 'Payment',
      title_en: 'Payment',
      route: 'payment',
      prevRoute: null,
      nextRoute: null,
      children: [
        {
          type: 'PredefinedContent',
          name: 'payment',
        },
      ],
      riskWeight: 0,
    },
    {
      type: 'Page',
      name: 'contra-account',
      title: 'Contra account',
      title_en: 'Contra account',
      route: 'contra-account',

      nextRoute: 'thank-you',
      children: [
        {
          type: 'PredefinedContent',
          name: 'contra-account',
        },
      ],
      riskWeight: 0,
    },
    {
      type: 'Page',
      name: 'thank-you',
      title: 'Bedankt',
      title_en: 'Thank you',
      route: 'thank-you',
      prevRoute: null,
      children: [
        {
          type: 'InfoPage',
          name: 'identification-info',
          header: {
            type: 'InfoPageHeader',
            title: 'Bedankt',
            title_en: 'Thank you',
            backgroundColor: '#E7EBEF',
            textColor: '#416F7E',
            icon: 'M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4',
          },
          content: {
            type: 'InfoPageContent',
            content:
              '<h3>Hartelijk bedankt</h3><p>U zult een email ontvangen wanneer uw aanvraag volledig is ontvangen. Daaropvolgend ontvangt u een email wanneer het account geaccepteerd is, inclusief de vervolgstappen.</p>',
            content_en:
              '<h3>Thank you</h3><p>You will receive an email once your application is received and a follow-up email telling you whether it has been approved, plus any next steps.</p>',
          },
        },
      ],
      riskWeight: 0,
    },
  ],
}
export default onboardingFlow
