import React from 'react'
import BaseFormComponentProps from '@/common/components/base-form-component-props'
import ValidationError from '@/common/components/forms/validation-error'

type Props = BaseFormComponentProps & {
  disabled?: boolean
  defaultValue?: string | number
  placeholder?: string
  value?: string | number
}
const TextInput = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      wrapperClassName = '',
      className = '',
      label,
      errors,
      onChange,
      onBlur,
      name,
      disabled,
      labelClassName,
      defaultValue,
      placeholder,
      value,
    }: Props,
    ref,
  ) => (
    <div className={`w-full ${wrapperClassName}`}>
      <label
        htmlFor={name}
        className={`block text-sm font-medium pb-1 ${labelClassName}`}
      >
        {label}
      </label>
      <input
        value={value}
        data-test-id={name}
        name={name}
        type="text"
        onChange={onChange}
        onBlur={onBlur}
        ref={ref}
        id={name}
        defaultValue={defaultValue}
        className={`focus:ring-secondary focus:border-secondary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${className}`}
        disabled={disabled}
        placeholder={placeholder}
      />
      {errors && <ValidationError errors={errors} name={name} />}
    </div>
  ),
)

export default TextInput
