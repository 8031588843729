import { BasicChartData } from '@/portal/components/basic-chart'
import { subDays } from 'date-fns'

//TODO: Why 26 values? Should we keep the default 26 for length or pass it each time?
const arrayWithValuesInRange = (max: number, min: number, length = 26) => {
  return Array.from({ length }, () => ({
    value: +(Math.random() * ((max - min) / 1.5) + min).toFixed(2),
  }))
}

const generatedValues = [
  { value: 302445.9 },
  { value: 302445.9 },
  { value: 302445.9 },
  ...arrayWithValuesInRange(302445.9, 302445.9),
  { value: 302445.9 },
  { value: 302445.9 },
  { value: 302445.9 },
  { value: 302445.9 },
  ...arrayWithValuesInRange(308364.05, 302445.9),
  { value: 308364.05 },
  { value: 308364.05 },
  { value: 308364.05 },
  { value: 308364.05 },
  ...arrayWithValuesInRange(315022.1, 308364.05),
  { value: 315022.1 },
  { value: 315022.1 },
  { value: 315022.1 },
  { value: 315022.1 },
  ...arrayWithValuesInRange(321419.96, 315022.1),
  { value: 321419.96 },
  { value: 321419.96 },
  { value: 321419.96 },
  { value: 321419.96 },
  ...arrayWithValuesInRange(328019.82, 321419.96),
  { value: 328019.82 },
  { value: 328019.82 },
  { value: 328019.82 },
  { value: 328019.82 },
  ...arrayWithValuesInRange(309616.2, 328019.82),
  { value: 309616.2 },
  { value: 309616.2 },
  { value: 309616.2 },
  { value: 309616.2 },
  ...arrayWithValuesInRange(317132.37, 309616.2),
  { value: 317132.37 },
  { value: 317132.37 },
  { value: 317132.37 },
  { value: 317132.37 },
  ...arrayWithValuesInRange(325753.27, 317132.37),
  { value: 325753.27 },
  { value: 325753.27 },
  { value: 325753.27 },
  { value: 325753.27 },
  ...arrayWithValuesInRange(334152.42, 325753.27),
  { value: 334152.42 },
  { value: 334152.42 },
  { value: 334152.42 },
  { value: 334152.42 },
  ...arrayWithValuesInRange(341968.64, 334152.42),
  { value: 341968.64 },
  { value: 341968.64 },
  { value: 341968.64 },
  { value: 341968.64 },
  ...arrayWithValuesInRange(349734.43, 341968.64),
  { value: 349734.43 },
  { value: 349734.43 },
  { value: 349734.43 },
  { value: 349734.43 },
  ...arrayWithValuesInRange(357404.52, 349734.43),
  { value: 357404.52 },
  { value: 357404.52 },
  { value: 357404.52 },
  { value: 357404.52 },
]

const deltaFromToday = generatedValues.length
const today = new Date()

export const mockData: BasicChartData[] = generatedValues
  .reverse()
  .map((item, index) => {
    const date = subDays(today, index)
    return {
      date: new Date(date.getTime() + deltaFromToday)
        .toISOString()
        .split('T')[0],
      value: item.value,
    }
  })
