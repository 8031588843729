import { BasicChartData } from '@/portal/components/fund-chart'
import { DataTypeFM } from '@/portal/views/fund-manager-dashboard'
import { subMonths } from 'date-fns'
import generatedData from './fund-manager-data.json'

export const initialData: Omit<DataTypeFM, 'month' | 'year'> = {
  data: {
    funds: [
      { label: 'Profitable fund', value: 'PF' },
      { label: 'Other fund', value: 'OF' },
    ],
    fund_stats: [
      { name: 'Fondswaarde', value: '€ 2.276.000' },
      { name: 'Maandrendement', value: '1,00%' },
      { name: 'Rendement YTD', value: '10,00%' },
      { name: 'Registraties', value: '273' },
    ],
    details_data: {
      title: 'Details',
      investments: {
        caption: '',
        table: [
          { name: 'Investeringen', value: '€ 2.760.000' },
          { name: 'Fondswaarde', value: '€ 2.800.000' },
          { name: 'Ongerealiseerde winst/verlies', value: '(€ 100.000)' },
        ],
      },
      cash: {
        caption: '',
        table: [
          { name: 'Cash', value: '€ 110.000' },
          { name: 'Afgewikkeld', value: '€ 130.000' },
          { name: 'Rente', value: '€ 20.000' },
        ],
      },
      nav: { caption: '', table: [{ name: 'NAV', value: '€ 110.000' }] },
    },
    fund_data: {
      title: 'Gegevens fonds',
      preferred: {
        caption: 'Preferable Fund',
      },
      administrator: {
        table: [
          { name: 'Beheerder', value: 'Juridisch Eigenaar' },
          {
            name: 'Profitable Fund B.V.',
            value: 'St. Jur. Eig. Profitable Fund',
          },
        ],
      },
      address: {
        caption: '',
        table: [
          { name: 'Adres', value: '' },
          { name: 'Wandelpad 34 2345 AA Amsterdam', value: '' },
        ],
      },
    },
    total: {
      title: 'Totaal',
      overview: {
        caption: 'Overzicht',
        table: [
          { name: 'Antaal Participaties', value: '2.000' },
          { name: 'Totale waarde Participaties', value: '211.000,00' },
          { name: 'Vorige totale waarde Participaties', value: '208.910,90' },
          { name: 'Maandrendement', value: '1.00%' },
        ],
      },
    },
    monthly: {
      title: 'Maand',
      tables: {},
      month_overview: {
        caption: '',
        headers: { name: '', num: 'Gestort bedrag', value: 'Participaties' },
        table: [
          { name: 'Vorig', value: '25.000', num: '€ 2.500.000' },
          {
            name: 'Totale waarde Participaties',
            value: '1.000',
            num: '€ 100.000',
          },
          { name: 'Huidig', value: '26.000', num: '€ 2.600.000' },
        ],
      },
    },
    fund_portfolio: {
      title: 'Fonds portfolio',
      market_instrument: {
        headers: { name: 'Markt Instrument', num: 'Aantal', value: 'Waarde' },
        table: [
          { name: 'Aandelen ASML', num: '10.000', value: '€ 1.523.000' },
          { name: 'Aandelen Philips', num: '25.000', value: '€ 324.000' },
          { name: 'Aandelen VDL', num: '15.000', value: '€ 324.000' },
          { name: 'Aandelen Capgemini', num: '7.500', value: '€ 24.000' },
          { name: 'Aandelen Unilever', num: '7.500', value: '€ 24.000' },
          { name: 'Aandelen Heineken', num: '7.500', value: '€ 24.000' },
          { name: 'Obligaties', num: '20.000', value: '€ 734.000' },
        ],
      },
    },
  },
}

const testData: Omit<DataTypeFM, 'month' | 'year'>[] = generatedData
  .reverse()
  .map((data) => {
    return {
      data: {
        funds: [
          { label: 'Profitable fund', value: 'PF' },
          { label: 'Other fund', value: 'OF' },
        ],
        fund_stats: [
          {
            name: 'Fondswaarde',
            value: `€ ${new Intl.NumberFormat('nl-NL').format(
              data.fondsWaarde,
            )}`,
          },
          { name: 'Maandrendement', value: `${data.maandRendement}%` },
          { name: 'Rendement YTD', value: `${data.rendementYTD}%` },
          { name: 'Registraties', value: `${data.registraties}` },
        ],
        details_data: {
          title: 'Details',
          investments: {
            caption: '',
            table: [
              {
                name: 'Investeringen',
                value: `€ ${new Intl.NumberFormat('nl-NL').format(
                  data.investeringen,
                )}`,
              },
              {
                name: 'Fondswaarde',
                value: `€ ${new Intl.NumberFormat('nl-NL').format(
                  data.posities,
                )}`,
              },
              {
                name: 'Ongerealiseerde winst/verlies',
                value: `(€ ${new Intl.NumberFormat('nl-NL').format(
                  data.ongerealiseerdeWinstVerlies,
                )})`,
              },
            ],
          },
          cash: {
            caption: '',
            table: [
              {
                name: 'Cash',
                value: `€ ${new Intl.NumberFormat('nl-NL').format(data.cash)}`,
              },
              {
                name: 'Afgewikkeld',
                value: `€ ${new Intl.NumberFormat('nl-NL').format(
                  data.afgewikkeld,
                )}`,
              },
              {
                name: 'Rente',
                value: `€ ${new Intl.NumberFormat('nl-NL').format(data.rente)}`,
              },
            ],
          },
          nav: {
            caption: '',
            table: [
              {
                name: 'NAV',
                value: `€ ${new Intl.NumberFormat('nl-NL').format(data.nav)}`,
              },
            ],
          },
        },
        fund_data: {
          title: 'Gegevens fonds',
          preferred: {
            caption: 'Preferable Fund',
          },
          administrator: {
            table: [
              { name: 'Beheerder', value: 'Juridisch Eigenaar' },
              {
                name: 'Profitable Fund B.V.',
                value: 'St. Jur. Eig. Profitable Fund',
              },
            ],
          },
          address: {
            caption: '',
            table: [
              { name: 'Adres', value: '' },
              { name: 'Wandelpad 34 2345 AA Amsterdam', value: '' },
            ],
          },
        },
        total: {
          title: 'Totaal',
          overview: {
            caption: 'Overzicht',
            table: [
              {
                name: 'Antaal Participaties',
                value: `${new Intl.NumberFormat('nl-NL').format(
                  data.aantalParticipatiesTotaal,
                )}`,
              },
              {
                name: 'Totale waarde Participaties',
                value: `${new Intl.NumberFormat('nl-NL').format(
                  data.totaleWaardeParticipatiesTotaal,
                )}`,
              },
              {
                name: 'Vorige totale waarde Participaties',
                value: `${new Intl.NumberFormat('nl-NL').format(
                  data.vorigeTotaleWaardeParticipatiesTotaal,
                )}`,
              },
              {
                name: 'Maandrendement',
                value: `${data.maandRendementTotaal}%`,
              },
            ],
          },
        },
        monthly: {
          title: 'Maand',
          tables: {},
          month_overview: {
            caption: '',
            headers: {
              name: '',
              num: 'Gestort bedrag',
              value: 'Participaties',
            },
            table: [
              {
                name: 'Vorig',
                value: `${new Intl.NumberFormat('nl-NL').format(
                  data.vorigParticipaties,
                )}`,
                num: `€ ${new Intl.NumberFormat('nl-NL').format(
                  data.vorigGestortBedrag,
                )}`,
              },
              {
                name: 'Totale waarde Participaties',
                value: `${new Intl.NumberFormat('nl-NL').format(
                  data.totaleWaardeParticipatiesParticipaties,
                )}`,
                num: `€ ${new Intl.NumberFormat('nl-NL').format(
                  data.totalaWaardeParticipateisGestortBedrag,
                )}`,
              },
              {
                name: 'Huidig',
                value: `${new Intl.NumberFormat('nl-NL').format(
                  data.huidigParticipaties,
                )}`,
                num: `€ ${new Intl.NumberFormat('nl-NL').format(
                  data.huidigGestortBedrag,
                )}`,
              },
            ],
          },
        },
        fund_portfolio: {
          title: 'Fonds portfolio',
          market_instrument: {
            headers: {
              name: 'Markt Instrument',
              num: 'Aantal',
              value: 'Waarde',
            },
            table: [
              {
                name: 'Aandelen ASML',
                num: `${new Intl.NumberFormat('nl-NL').format(
                  data.asmlAantal,
                )}`,
                value: `€ ${new Intl.NumberFormat('nl-NL').format(
                  data.asmlWaarde,
                )}`,
              },
              {
                name: 'Aandelen Philips',
                num: `${new Intl.NumberFormat('nl-NL').format(
                  data.philipsAantal,
                )}`,
                value: `€ ${new Intl.NumberFormat('nl-NL').format(
                  data.philipsWaarde,
                )}`,
              },
              {
                name: 'Aandelen Adyen',
                num: `${new Intl.NumberFormat('nl-NL').format(
                  data.adyenAantal,
                )}`,
                value: `€ ${new Intl.NumberFormat('nl-NL').format(
                  data.adyenWaarde,
                )}`,
              },
              {
                name: 'Aandelen Wolters Kluwer',
                num: `${new Intl.NumberFormat('nl-NL').format(
                  data.woltersKluwerAantal,
                )}`,
                value: `€ ${new Intl.NumberFormat('nl-NL').format(
                  data.woltersKluwerWaarde,
                )}`,
              },
              {
                name: 'Aandelen Unilever',
                num: `${new Intl.NumberFormat('nl-NL').format(
                  data.unileverAantal,
                )}`,
                value: `€ ${new Intl.NumberFormat('nl-NL').format(
                  data.unileverWaarde,
                )}`,
              },
              {
                name: 'Aandelen Heineken',
                num: `${new Intl.NumberFormat('nl-NL').format(
                  data.heinekenAantal,
                )}`,
                value: `€ ${new Intl.NumberFormat('nl-NL').format(
                  data.heinekenWaarde,
                )}`,
              },
            ],
          },
        },
      },
    }
  })

const months = [
  { label: 'januari', value: 1 },
  { label: 'februari', value: 2 },
  { label: 'maart', value: 3 },
  { label: 'april', value: 4 },
  { label: 'mei', value: 5 },
  { label: 'juni', value: 6 },
  { label: 'juli', value: 7 },
  { label: 'augustus', value: 8 },
  { label: 'september', value: 9 },
  { label: 'oktober', value: 10 },
  { label: 'november', value: 11 },
  { label: 'december', value: 12 },
]
export const time = {
  months,
  years: [
    { label: '2023', value: 2023 },
    { label: '2022', value: 2022 },
    { label: '2021', value: 2021 },
  ],
}

export const years = [0, 1, 2, 3].map((i) => {
  const year = new Date().getFullYear() - i
  return { label: year.toString(), value: year.toString() }
})

const deltaFromToday = generatedData.length
const today = new Date()

export const chartMockDataInvestments: BasicChartData[] = generatedData
  .map((item, index) => {
    const date = subMonths(today, index + 1)
    return {
      date: new Date(date.getTime() + deltaFromToday)
        .toISOString()
        .split('T')[0],
      value: item.investeringen / 1000,
    }
  })
  .slice(0, 24)

export const chartMockDataPositions: BasicChartData[] = generatedData
  .map((item, index) => {
    const date = subMonths(today, index + 1)
    return {
      date: new Date(date.getTime() + deltaFromToday)
        .toISOString()
        .split('T')[0],
      value: item.posities / 1000,
    }
  })
  .slice(0, 24)

export const data: DataTypeFM[] = testData.map((item, index) => {
  const date = subMonths(today, index + 1)
  const year = date.getFullYear()
  return {
    ...item,
    month: months[date.getMonth()],
    year: { label: year.toString(), value: year },
  }
})
