export const colorKeys = [
  'primaryColor',
  'secondaryColor',
  'backgroundColor',
  'textColor',
] as const

export type ColorKey = typeof colorKeys[number]
export type ImageKey = 'backgroundImage' | 'coverImage' | 'logo'

export type ThemeConfig = {
  name: string
  primaryColor: string | null
  secondaryColor: string | null
  backgroundColor: string | null
  textColor: string | null
  logo: string | null
  backgroundImage: string | null
  coverImage: string | null
}
