import React, { useEffect, useRef } from 'react'
import * as d3 from 'd3'
import useResizeObserver from 'use-resize-observer'
import { format } from 'date-fns'
import './basic-chart.scss'

export type BasicChartData = { date: string; value: number }

type Props = {
  data: BasicChartData[]
}

const render = (
  data: BasicChartData[],
  ref: React.MutableRefObject<HTMLDivElement | null>,
  width: number,
) => {
  d3.select(ref.current).select('.tooltip').remove()
  const tooltip = d3
    .select(ref.current)
    .append('div')
    .attr('class', 'tooltip')
    .style('position', 'absolute')
    .style('fill', 'white')
    .style('border', '#BF9578')
    .style('border-style', 'solid')
    .style('border-width', '1px')
    .style('background-color', 'white')
    .style('border-radius', '6px')
    .style('padding', '7px')
    .style('width', '150px')
    .style('font-size', '12px')
    .style('display', 'none')
    .style('pointer-events', 'none')
    .style('top', '-60px')

  const height = 300
  const margin = 50

  d3.select(ref.current).select('svg').remove()

  /* Scale */
  const xScale = d3
    .scaleLinear()
    .domain([
      data.reduce(
        (min, d) => Math.min(min, new Date(d.date).getTime()),
        Infinity,
      ),
      data.reduce((max, d) => Math.max(max, new Date(d.date).getTime()), 0),
    ])
    .range([0, width - margin])

  const cutOffValue = 1000
  const yScale = d3
    .scaleLinear()
    .domain([
      data.reduce((min, d) => Math.min(min, d.value), Infinity) - cutOffValue,
      data.reduce((max, d) => Math.max(max, d.value), 0) + cutOffValue,
    ])
    .range([height - margin, 0])

  /* Add SVG */
  const svg = d3
    .select(ref.current)
    .append('svg')
    .attr('width', width + 'px')
    .attr('height', height + 'px')

  const container = svg.append('g').attr('transform', `translate(0, ${margin})`)

  // add line
  container
    .append('path')
    .datum(data)
    .attr('fill', 'none')
    .attr('stroke', '#BF9578')
    .attr('stroke-width', 2)
    .attr('class', 'shadow')
    .attr(
      'd',
      d3
        .line<BasicChartData>()
        .x((d) => xScale(new Date(d.date).getTime()))
        .y((d) => yScale(d.value))
        .curve(d3.curveCatmullRom),
    )

  const focus = svg.append('g').style('display', 'none')

  // add vertical line
  focus
    .append('line')
    .attr('class', 'x')
    .style('stroke', '#394149')
    .style('opacity', 0.3)
    .attr('y1', 0)
    .attr('y2', height)

  focus
    .append('circle')
    .attr('class', 'y')
    .style('fill', 'none')
    .style('stroke', '#BF9578')
    .style('opacity', 0.5)
    .attr('r', 4)
    .attr('y', 0)

  svg
    .on('mouseout', function () {
      focus.style('display', 'none')
      tooltip.style('display', 'none')
    })
    .on('mousemove', mousemove)

  function mousemove(event: any) {
    const xMousePosition = d3.pointer(event, container.node())[0] // gets [x,y]
    const tooltipWidth = 150
    const currentDate = format(
      new Date(xScale.invert(xMousePosition)),
      'yyyy-MM-dd',
    ) // converts x to date

    const hoverElement = data.find((d) => d.date === currentDate)
    const xCoordinate =
      hoverElement && xScale(new Date(hoverElement.date).getTime())

    if (hoverElement && xCoordinate !== undefined) {
      focus
        .select('circle.y')
        .attr(
          'transform',
          `translate(${xCoordinate || 1}, ${yScale(hoverElement.value)})`,
        )

      focus
        .select('.x')

        .attr('transform', `translate(${xCoordinate || 1},0)`)
        .attr('y2', height + margin)

      const tooltipPosition =
        xMousePosition + tooltipWidth > width
          ? xMousePosition - tooltipWidth
          : xMousePosition

      tooltip
        .style('left', `${tooltipPosition}px`)
        .style('display', 'block')
        .html(`Saldo: € ${hoverElement.value}<br/>Date: ${hoverElement.date}`)

      focus.style('display', null)
    }
  }
}

const BasicLineChart: React.FC<Props> = ({ data }) => {
  const svgRef = useRef<HTMLDivElement | null>(null)
  const { width = 1 } = useResizeObserver<HTMLDivElement>({ ref: svgRef })

  useEffect(() => {
    render(data, svgRef, width)
  }, [data, svgRef.current, width])
  return (
    <div
      ref={svgRef}
      className="w-full"
      style={{ position: 'relative', marginTop: '60px' }}
    ></div>
  )
}

export default BasicLineChart
