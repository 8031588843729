import React, { useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import './app.scss'
import Navigation from '@/common/components/navigation'
import Messages from './views/messages'
import News from './views/news'
import Documents from './views/documents'
import Dashboard from './views/dashboard'
import axios, { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'
import AdminNavigation from '../admin/components/navigation'
import Users from '../admin/views/users'
import Configuration from '../admin/views/configuration'
import { User as UserModel, UserRole } from '@/core/models/user'
import Spinner from '@/common/components/spinner'
import Onboarding from '@/portal/views/onboarding'
import FundManagerDashboard from './views/fund-manager-dashboard'
import { useMsal } from '@azure/msal-react'
import { loginRequest } from '@/portal/msal-config'
import { InteractionStatus } from '@azure/msal-browser'
import { useRecoilState } from 'recoil'
import { userState } from '@/common/state/atoms/user-state'
import i18n from './i18n'
import PortfolioPage from '@/portal/views/portfolio-page'

const App: React.FC = () => {
  const { instance, accounts, inProgress } = useMsal()
  const [idToken, setIdToken] = React.useState<string | null>(null)
  const [user, setUserState] = useRecoilState(userState)

  useEffect(() => {
    if (inProgress === InteractionStatus.None && accounts.length > 0) {
      // Retrieve an access token
      instance
        .acquireTokenSilent({ ...loginRequest, account: accounts[0] })
        .then((response) => {
          if (response.idToken) {
            // Successfully retrieved an idToken
            axios.defaults.headers.common[
              'Authorization'
            ] = `Bearer ${response.idToken}`
            setIdToken(response.idToken)
            return response.idToken
          }
          return null
        })
        .catch((error) => console.log('token error', error))
    }
  }, [inProgress, accounts, instance])

  const fetchUser = useQuery(
    'fetchCurrentUser',
    (): Promise<AxiosResponse<UserModel>> => axios.get('users/current'),
    {
      enabled: idToken !== null,
      refetchOnWindowFocus: false,
    },
  )

  const fetchedUser = fetchUser.data?.data
  useEffect(() => {
    fetchedUser && setUserState(fetchedUser)
    void i18n.changeLanguage(fetchedUser?.language)
  }, [fetchedUser?.id])

  if (!user || !idToken) return <Spinner />

  return user?.role === UserRole.SUPERADMIN ? (
    <div className="App flex min-h-screen">
      <AdminNavigation />
      <div className="container w-full flex flex-wrap mx-auto px-2 pt-8 lg:pt-16 mt-8">
        <Routes>
          <Route path="admin/dashboard" element={<Dashboard />} />
          <Route path="admin/users" element={<Users />} />
          <Route path="admin/configuration" element={<Configuration />} />
          <Route
            path="*"
            element={<Navigate replace to="/admin/dashboard" />}
          />
        </Routes>
      </div>
    </div>
  ) : (
    <div className="App flex min-h-screen">
      {user.isActive ? (
        <>
          <Navigation />
          <div className="container w-full flex flex-wrap mx-auto px-2 pt-8 lg:pt-16 mt-8">
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route
                path="/reports"
                element={
                  user.role === UserRole.CLIENT ? (
                    <PortfolioPage />
                  ) : (
                    <FundManagerDashboard />
                  )
                }
              />
              <Route path="/news" element={<News />} />
              <Route path="/documents" element={<Documents />} />
              <Route path="/messages" element={<Messages />} />
            </Routes>
          </div>
        </>
      ) : (
        <div className="mx-auto py-10">
          <Routes>
            <Route path="onboarding/*" element={<Onboarding />} />
            <Route path="/" element={<Navigate to="onboarding" />} />
          </Routes>
        </div>
      )}
    </div>
  )
}

export default App
