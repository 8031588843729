export default {
  translation: {
    payment_method_ideal: 'Your payment method will be iDeal',
    payment_button_description: 'Click here to proceed with the payment.',
    logout: 'Logout',
    account_details: 'Account Details',
    please_select: 'Please, select one of the options',
    account_name: 'Account name',
    next: 'Next',
    previous: 'Previous',
    date_and_place_of_birth: 'Date and place of birth',
    date: 'Date',
    country: 'Country',
    city: 'City',
    citizenship_and_tax_residence: 'Citizenship and Tax Residence',
    citizenship: 'Citizenship',
    tax_residence_in_this_country: 'Tax residence in this country',
    tax_country: 'Tax country',
    tin: 'Tax Identification Number (BSN/TIN)',
    citizenship_other: 'Other citizenship',
    personal_info: 'Personal Info',
    first_name: 'First name',
    last_name: 'Last name',
    residence_address: 'Residence Address',
    description_permanent_address:
      'Use a permanent address where you can receive mail.',
    country_region: 'Country / Region',
    zip_postal: 'ZIP / Postal',
    street_number: 'House number',
    street_number_ext: 'Street number ext',
    street_name: 'Street name',
    mobile_phone: 'Mobile phone',
    sms_verification:
      'We will verify certain actions in our portal with an sms code',
    country_code: 'Country code',
    identification: 'Identification',
    passport_identification:
      'As part of the registration process we are obliged to ask you for a copy of your passport or identity card in order to identify you',
    username: 'Username',
    password: 'Password',
    forgotten_password: 'Forgot your password?',
    login: 'Login',
    signup: 'Sign up',
    warning_invalid_username_or_password:
      'Invalid username or password, or inactive user',
    email: 'E-mail',
    register: 'Register',
    required_fields_note: 'fields marked with * are mandatory',
    required_field: 'Required field',
    invalid_email_format: 'Invalid email format',
    invalid_text_format: 'Invalid text format',
    invalid_number_format: 'Invalid number format',
    invalid_input: 'Invalid input',
    invalid_tin_number_format: 'Invalid tax identification number format',
    invalid_iban: 'Invalid IBAN format',
    password_too_weak: 'Password is too weak',
    countries: {
      nl: 'Netherlands',
      be: 'Belgium',
      lu: 'Luxembourg',
    },
    account_type: {
      buffer: 'Buffer',
      growth: 'Growth',
      essential: 'Essential',
      non_essential: 'Non-essential',
      additional: 'Additional',
    },
    validations: {
      required: 'Required field',
    },
    investment_plan: 'Investment Plan',
    target_amount: 'Target amount',
    investment_period: 'Investment period (yrs)',
    initial_deposit: 'Initial deposit',
    monthly_deposit: 'Monthly deposit',
    occupation: 'Occupation',
    make_a_choice: 'Make a choice',
    button_name: {
      month: '1 M',
      six_months: '6 M',
      year_to_date: 'YTD',
    },
    amount: 'Amount',
    low_performance: 'Low performance',
    optimal_performance: 'Optimal performance',
    high_performance: 'High performance',
    monthly_report: 'Monthly report',
    profitable_fund: 'Profitable Fund',
    company_name: 'Company name',
    logo: 'Logo',
    cover_image: 'Cover image',
    background_image: 'Background image',
    neutral_risk_bottom_value: 'Neutral risk profile bottom value',
    neutral_risk_top_value: 'Neutral risk profile top value',
    preview: 'Preview',
    save_your_changes: 'Save your changes',
    primary_color: 'Primary color',
    secondary_color: 'Secondary color',
    background_color: 'Background color',
    text_color: 'Text color',
    drag_drop_files: 'Drag & Drop your files or',
    browse: 'Browse',
    id: 'ID',
    status: 'Status',
    role: 'Role',
    add_user: 'Add user',
    edit_user: 'Edit user',
    cancel: 'Cancel',
    save: 'Save',
    active: 'Active',
    dashboard: 'Dashboard',
    users: 'Users',
    configuration: 'Configuration',
    log_out: 'Log out',
    payment: 'Payment',
    allow_automatic_debit: 'Automatic Debit',
    automatic_debit_description:
      'Allow {{company}} to deduct a selected amount of euro per month as an automatic deduction. Can be stopped at any time.',
    contra_account: 'Contra Account',
    account_register: 'Account is registered on the  following name',
    iban: 'IBAN',
    contra_account_description:
      'If you want to extract money from your funds account, to which can we transfer the money?',
    account_purpose: 'Please state the purpose of your new account',
    please_select_account_purpose:
      'Please select account purpose from the options above',
    payment_status: {
      success: 'Payment was successful',
      failed_or_canceled_retry: 'Payment failed or canceled, please try again',
      retry: 'Something went wrong, please try again later',
    },
    occupation_status: {
      occupation_field: 'Occupation field',
      what_is_your_occupation_status: 'What is your employment situation?',
      werknemer_in_bedrifsleven: 'Employee in business',
      werknemer_semi_overheidsinstelling: 'Employee (semi-)government agency',
      partner_in_een_onderneming: 'Partner in a company',
      bestuurder_neiet_grootaandeelhouder:
        'Director / non-majority shareholder',
      ondernemer: 'Entrepreneur / Freelancer',
      student: 'Student',
      gepensioneerd: 'Retired',
      werkloos: 'Unemployed',
    },
    occupation_field: {
      bestuur_en_management: 'Board and management',
      bouw_en_infrastructuur: 'Construction and infrastructure',
      financieel: 'Financial',
      handel: 'Trading',
      ict_telecom_en_online: 'ICT, telecom and online',
      horeca: 'Catering industry',
      industrie: 'Industry',
      kunst_amusement_en_recreatie: 'Arts, entertainment and recreation',
      onderwijs_en_overheid: 'Education and government',
      landbouw_bosbouw_en_visserij: 'Agriculture, forestry and fishing',
      vervoer_transport_en_logistiek: 'Transport, transport and logistics',
      sport: 'Sport',
      vastgoed: 'Real estate',
      zakelijk_en_commercieel: 'Business and commercial',
      zorg_en_welzijn: 'Care and welfare',
      anders: 'Other',
    },
    occupation_sector: {
      occupation_sector: 'Occupation sector',
      geen_van_onderstaande: 'No, none of the below',
      trustkantoren: 'Trust offices (Corporate Service Providers)',
      afvalbeheer_en_sanering: 'Waste management and remediation',
      offshore: 'Offshore',
      watervoorziening_en_riolering: 'Water supply and sewage',
      betalingsdiensten_of_vergelijkbaar: 'Payment services or similar (PSP)',
      crowdfunding: 'Crowdfunding',
      handel_in_cryptovaluta_of_initial_coin_offering:
        'Cryptocurrency Trading or Initial Coin Offering (ICO)',
      verzekeringen: 'Insurances',
    },
  },
}
