import React, { PropsWithChildren, useState } from 'react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'
import '../switch/switch.scss'

type Props = {
  wrapperClassName?: string
  name: string
  additionalInfo: string
}

const Expand: React.FC<PropsWithChildren<Props>> = ({
  wrapperClassName = '',
  additionalInfo,
  children,
}) => {
  const [expanded, setExpanded] = useState(false)
  return (
    <div
      className={`border border-gray-300 rounded-md p-3 ${wrapperClassName}`}
    >
      <div className="flex justify-between">
        {children}
        <button
          type="button"
          onClick={() => setExpanded(!expanded)}
          className="items-center p-1 border border-gray-300 rounded-full shadow-sm text-gray-300 hover:text-gray-500 hover:border-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
        >
          {expanded ? (
            <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
          ) : (
            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
          )}
        </button>
      </div>
      {expanded && (
        <p className="block text-sm font-light pb-2">{additionalInfo}</p>
      )}
    </div>
  )
}

export default Expand
