import React, { PropsWithChildren } from 'react'
import './card.scss'

type Props = {
  title?: string
}

const TableCard: React.FC<PropsWithChildren<Props>> = ({ title, children }) => (
  <div className="card perSeriesTable w-full">
    {title && <h2>{title}</h2>}
    {children}
  </div>
)

export default TableCard
