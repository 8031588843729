import React, { useEffect, useMemo, useState } from 'react'
import {
  FieldErrors,
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import FormLayout from '@/portal/components/form-layout'
import styles from '../onboarding.module.scss'
import InvestmentPlanChart, {
  ChartLineData,
} from '@/portal/components/investment-plan-chart'
import TextInput from '@/portal/components/text-input'
import { useRecoilState } from 'recoil'
import { investmentPlanState } from '@/common/state/atoms/investment-plan-state'
import { regexPositiveNumbers, regexZeroAndPositiveNumbers } from '@/core/regex'

type Props = {
  register: UseFormRegister<FieldValues>
  watch: UseFormWatch<FieldValues>
  setValue: UseFormSetValue<FieldValues>
  errors: FieldErrors
  path: string
}

const InvestmentPlan: React.FC<Props> = ({
  register,
  watch,
  setValue,
  errors,
  path,
}) => {
  const { t } = useTranslation()
  const [invalidData, setInvalidData] = useState(false)
  const [investmentPlanConfig] = useRecoilState(investmentPlanState)
  const {
    defaultInvestmentPeriod,
    targetAmountDefault,
    initialDepositDefault,
    monthlyDepositDefault,
    performanceLevels,
  } = investmentPlanConfig

  const [
    targetAmount = targetAmountDefault,
    investmentPeriod = defaultInvestmentPeriod,
    initialDeposit = initialDepositDefault,
    monthlyDeposit = monthlyDepositDefault,
  ] = watch([
    `${path}.targetAmount`,
    `${path}.investmentPeriod`,
    `${path}.initialDeposit`,
    `${path}.monthlyDeposit`,
  ])

  useEffect(() => {
    setValue(`${path}.targetAmount`, targetAmount)
    setValue(`${path}.investmentPeriod`, investmentPeriod)
    setValue(`${path}.initialDeposit`, initialDeposit)
    setValue(`${path}.monthlyDeposit`, monthlyDeposit)
  }, [targetAmount, investmentPeriod, initialDeposit, monthlyDeposit])

  useEffect(() => {
    if (
      errors.initialDeposit ||
      errors.monthlyDeposit ||
      errors.investmentPeriod
    ) {
      setInvalidData(true)
    } else {
      setInvalidData(false)
    }
  }, [errors.initialDeposit, errors.monthlyDeposit, errors.investmentPeriod])

  const calculatedData = useMemo(() => {
    const totalAmount: ChartLineData[] = []
    for (let j = 0; j < performanceLevels.length; j++) {
      totalAmount.push({ name: performanceLevels[j].name, values: [] })
      let currentTotal = +initialDeposit
      for (let i = 0; i <= investmentPeriod; i++) {
        totalAmount[j].values.push({
          year: i,
          amount: currentTotal,
        })
        currentTotal = parseFloat(
          (
            currentTotal +
            (currentTotal * performanceLevels[j].value) / 100 +
            monthlyDeposit * 12
          ).toFixed(2),
        )
      }
    }
    return totalAmount
  }, [initialDeposit, monthlyDeposit, investmentPeriod])

  return (
    <>
      <FormLayout title={t('investment_plan')}>
        <TextInput
          wrapperClassName={styles.inputWrapperSmSpan3}
          label={t('target_amount')}
          defaultValue={targetAmountDefault}
          {...register(`${path}.targetAmount`, {
            pattern: {
              value: regexPositiveNumbers,
              message: `${t('invalid_number_format')}`,
            },
          })}
          errors={errors}
        />
        <TextInput
          wrapperClassName={styles.inputWrapperSmSpan3}
          label={t('investment_period')}
          defaultValue={defaultInvestmentPeriod}
          {...register(`${path}.investmentPeriod`, {
            required: `${t('required_field')}`,
            pattern: {
              value: regexPositiveNumbers,
              message: `${t('invalid_number_format')}`,
            },
          })}
          errors={errors}
        />
        <TextInput
          wrapperClassName={styles.inputWrapperSmSpan3}
          label={t('initial_deposit')}
          defaultValue={initialDepositDefault}
          {...register(`${path}.initialDeposit`, {
            required: `${t('required_field')}`,
            pattern: {
              value: regexZeroAndPositiveNumbers,
              message: `${t('invalid_number_format')}`,
            },
          })}
          errors={errors}
        />
        <TextInput
          wrapperClassName={styles.inputWrapperSmSpan3}
          label={t('monthly_deposit')}
          defaultValue={monthlyDepositDefault}
          {...register(`${path}.monthlyDeposit`, {
            required: `${t('required_field')}`,
            pattern: {
              value: regexZeroAndPositiveNumbers,
              message: `${t('invalid_number_format')}`,
            },
          })}
          errors={errors}
        />
        <InvestmentPlanChart
          invalidData={invalidData}
          data={calculatedData}
          targetAmount={errors.targetAmount ? null : targetAmount}
        />
      </FormLayout>
    </>
  )
}

export default InvestmentPlan
