export const regexEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const regexOnlyLetters = /^[a-zA-Z- ]*$/g
export const regexOnlyNumbers = /^[0-9- ]*$/g
export const regexZeroAndPositiveNumbers = /^[0-9]*$/g
export const regexPositiveNumbers = /^[1-9][0-9]*$/g
export const regexCountryCode = /^\+?\d+$/g
export const regexTin = /^[a-zA-Z0-9-+. ]*$/g

export const postCodeRegex =
  /^(?:[1-9]\d{3} ?(?:[A-EGHJ-NPRTVWXZ][A-EGHJ-NPRSTVWXZ]|S[BCEGHJ-NPRTVWXZ]))$/i
