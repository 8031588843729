import React, { PropsWithChildren } from 'react'
import { FieldErrors } from 'react-hook-form'
import { get } from 'lodash'

type Props = {
  errors: FieldErrors
  name: string
}
const ValidationError: React.FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  name,
  errors,
}) => {
  const error = get(errors, name)
  return error ? (
    <span className="text-xs text-red-700">
      {children ?? error.message ?? 'Invalid field'}
    </span>
  ) : null
}

export default ValidationError
