import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './en'
import nl from './nl'
import { defaultLanguage } from '@/core/models/i18n'

// the translations// (tip move them in a JSON file and import them,// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
//
export const languages = [defaultLanguage, 'nl'] as const
i18n.use(initReactI18next).init({
  resources: {
    en,
    nl,
  },
  lng: 'en',
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
