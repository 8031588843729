import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import BaseFormComponentProps from '@/common/components/base-form-component-props'
import ValidationError from '@/common/components/forms/validation-error'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

type Props = BaseFormComponentProps & {
  navigation: { name: string; value: string; icon: IconProp }[]
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const RadioInput = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      errors,
      onChange,
      onBlur,
      name,
      navigation,
      label,
      labelClassName,
    }: Props,
    ref,
  ) => {
    return (
      <div className="col-span-6">
        <label
          htmlFor={name}
          className={`block text-sm font-medium pb-1 ${labelClassName}`}
        >
          {label}
        </label>
        <nav className="space-y-3 py-1" data-test-id={name}>
          {navigation.map((item) => (
            <div key={item.name}>
              <input
                type="radio"
                onChange={onChange}
                onBlur={onBlur}
                ref={ref}
                id={item.value}
                value={item.value}
                className="opacity-0 absolute"
                name={name}
              />
              <label htmlFor={item.value} className="bank-account-label">
                <FontAwesomeIcon
                  size="lg"
                  icon={item.icon}
                  className={classNames(
                    'bank-account-icon',
                    'flex-shrink-0 -ml-1 mr-3 h-6 w-6',
                  )}
                  aria-hidden="true"
                />
                <span className="truncate">{item.name}</span>
              </label>
            </div>
          ))}
        </nav>
        {errors && <ValidationError errors={errors} name={name} />}
      </div>
    )
  },
)

export default RadioInput
