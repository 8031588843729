import React from 'react'
import { useTranslation } from 'react-i18next'
import InfoPage from '@/portal/components/info-page'

const FinalPage: React.FC = () => {
  const { t } = useTranslation()

  return (
    <InfoPage
      header={{
        type: 'InfoPageHeader',
        title: t('Thank you'),
        backgroundColor: '#E7EBEF',
        textColor: '#416F7E',
        icon: 'M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4',
      }}
      content={{
        type: 'InfoPageContent',
        content:
          '<h3>Thank you</h3><p>You will receive an email once your application is received and a follow-up email telling you whether it has been approved, plus any next steps.</p><p className="text-sm">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ipsum tincidunt sollicitudin nunc eu dolor. Amet nec lectus lorem dolor. Ac urna at nisl, risus rhoncus. Volutpat ornare neque feugiat sit aenean scelerisque aenean pellentesque aliquam. Nec posuere enim proin massa. Nunc hendrerit urna cursus sit cursus mi.</p>',
      }}
    />
  )
}

export default FinalPage
