import React from 'react'
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import axios from 'axios'
import { countries as untranslatedCountries } from '@/core/models/countries'
import TextInput from '../../components/text-input'
import FormLayout from '../../components/form-layout'
import styles from './onboarding.module.scss'
import { useTranslation } from 'react-i18next'
import { requiredWithMessage } from '@/common/models/validation'
import { useQuery } from 'react-query'
import { AddressSearchResult } from '@/core/models/address-search.dto'
import { regexOnlyNumbers, regexCountryCode } from '../../../../core/src/regex'
import Select from '@/portal/components/select-input'

type Props = {
  register: UseFormRegister<FieldValues>
  watch: UseFormWatch<FieldValues>
  errors: FieldErrors
  setValue: UseFormSetValue<FieldValues>
  control?: Control<FieldValues> | undefined
  path: string
}

const PersonalDetails: React.FC<Props> = ({
  register,
  errors,
  control,
  watch,
  setValue,
  path,
}) => {
  const { t } = useTranslation()
  const [country, postcode, streetNumber, streetNumberExtension] = watch([
    `${path}.country`,
    `${path}.postcode`,
    `${path}.streetNumber`,
    `${path}.streetNumberExtension`,
  ])
  const sanitizedStreetNumber = streetNumber?.replace(/\s/g, '')
  const sanitizedPostcode = postcode?.replace(/\s/g, '')

  // if (country) {return}
  useQuery(
    [
      'fetchSearchAddressData',
      sanitizedPostcode,
      sanitizedStreetNumber,
      streetNumberExtension,
    ],
    async () => {
      const res = await axios.get('/onboarding-flows/address-search', {
        params: {
          postcode: sanitizedPostcode,
          streetNumber: sanitizedStreetNumber,
          streetNumberExtension,
        },
      })
      return res?.data
    },
    {
      onSuccess: (data: AddressSearchResult) => {
        if (data) {
          setValue(`${path}.city`, data.city)
          setValue(`${path}.streetName`, data.streetName)
        } else {
          setValue(`${path}.city`, '')
          setValue(`${path}.streetName`, '')
        }
      },
      cacheTime: 5 * 60 * 1000,
      // staleTime: 10000,
      enabled:
        !!(sanitizedPostcode && sanitizedStreetNumber) && country === 'nl',
    },
  )
  const countries = untranslatedCountries.map(({ value, label }) => ({
    value,
    label: t(`${label}`),
  }))

  return (
    <>
      <FormLayout dataTestId="PersonalDetails" title={t('personal_info')}>
        <TextInput
          wrapperClassName={styles.inputWrapperSmSpan3}
          label={t('first_name') + ' *'}
          {...register(`${path}.firstName`, requiredWithMessage)}
          errors={errors}
        />

        <TextInput
          wrapperClassName={styles.inputWrapperSmSpan3}
          label={t('last_name') + ' *'}
          {...register(`${path}.lastName`, requiredWithMessage)}
          errors={errors}
        />
      </FormLayout>

      <FormLayout
        dataTestId="ResidenceAddress"
        title={t('residence_address')}
        description={t('description_permanent_address')}
      >
        <Controller
          name={`${path}.country`}
          control={control}
          render={({ field }) => {
            const { onChange, value, ref: _, ...rest } = field

            return (
              <Select
                {...rest}
                name={`${path}.country`}
                wrapperClassName={`${styles.inputWrapperSmSpan3}`}
                label={t('country_region') + ' *'}
                labelClassName={styles.labelName}
                errors={errors}
                options={countries}
                onChange={(val) => onChange(val)}
                value={value}
              />
            )
          }}
          rules={requiredWithMessage}
        />

        <TextInput
          wrapperClassName={styles.inputWrapperSmSpan3}
          label={t('zip_postal') + ' *'}
          {...register(`${path}.postcode`, requiredWithMessage)}
          errors={errors}
        />

        <TextInput
          wrapperClassName={styles.inputWrapperSmSpan3}
          label={t('street_number') + ' *'}
          {...register(`${path}.streetNumber`, {
            required: `${t('required_field')}`,
            pattern: {
              value: regexOnlyNumbers,
              message: `${t('invalid_number_format')}`,
            },
          })}
          errors={errors}
        />

        <TextInput
          wrapperClassName={styles.inputWrapperSmSpan3}
          label={t('street_number_ext')}
          {...register(`${path}.streetNumberExtension`)}
          errors={errors}
        />

        <TextInput
          wrapperClassName={styles.inputWrapperBasic}
          label={t('street_name') + ' *'}
          {...register(`${path}.streetName`, requiredWithMessage)}
          errors={errors}
        />

        <TextInput
          wrapperClassName={styles.inputWrapperSmSpan3}
          label={t('city') + ' *'}
          {...register(`${path}.city`, requiredWithMessage)}
          errors={errors}
        />
      </FormLayout>

      <FormLayout
        dataTestId="MobilePhone"
        title={t('mobile_phone')}
        description={t('sms_verification')}
      >
        <TextInput
          wrapperClassName="col-span-6 sm:col-span-1"
          label={t('country_code') + ' *'}
          labelClassName="whitespace-nowrap"
          {...register(`${path}.countryCode`, {
            required: `${t('required_field')}`,
            pattern: {
              value: regexCountryCode,
              message: `${t('invalid_number_format')}`,
            },
          })}
          errors={errors}
          defaultValue="+31"
        />

        <TextInput
          wrapperClassName="col-span-6 sm:col-span-3 lg:col-span-2"
          label={t('mobile_phone') + ' *'}
          {...register(`${path}.mobilePhone`, {
            required: `${t('required_field')}`,
            pattern: {
              value: regexOnlyNumbers,
              message: `${t('invalid_number_format')}`,
            },
          })}
          errors={errors}
        />
      </FormLayout>
    </>
  )
}

export default PersonalDetails
