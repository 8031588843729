import React, { useState } from 'react'
import { requiredWithMessage } from '@/common/models/validation'
import Select from '@/portal/components/select-input'
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styles from '../onboarding.module.scss'
import FormLayout from '@/portal/components/form-layout'
import { isEmpty } from 'lodash'

type Props = {
  register: UseFormRegister<FieldValues>
  watch: UseFormWatch<FieldValues>
  errors: FieldErrors
  setValue: UseFormSetValue<FieldValues>
  control?: Control<FieldValues> | undefined
  path: string
}

type OptionItem = {
  key: string
  label: string
  // label_en: string
  value: string
  options?: Array<OptionItem>
}

type OptionItems = {
  occupation_status: OptionItem
  occupation_field: OptionItem
}

const data: OptionItems = {
  occupation_status: {
    label: 'occupation_status.what_is_your_occupation_status',
    key: 'occupation_status',
    value: 'occupation_status',
    options: [
      {
        label: 'occupation_status.werknemer_in_bedrifsleven',
        key: 'werknemer_in_bedrifsleven',
        value: '1',
      },
      {
        label: 'occupation_status.werknemer_semi_overheidsinstelling',
        key: 'werknemer_semi_overheidsinstelling',
        value: '2',
      },
      {
        label: 'occupation_status.partner_in_een_onderneming',
        key: 'partner_in_een_onderneming',
        value: '3',
      },
      {
        label: 'occupation_status.bestuurder_neiet_grootaandeelhouder',
        key: 'bestuurder_neiet_grootaandeelhouder',
        value: '4',
      },
      {
        label: 'occupation_status.ondernemer',
        key: 'ondernemer',
        value: '5',
      },
      {
        label: 'occupation_status.student',
        key: 'student',
        value: '6',
      },
      {
        label: 'occupation_status.gepensioneerd',
        key: 'gepensioneerd',
        value: '7',
      },
      {
        label: 'occupation_status.werkloos',
        key: 'werkloos',
        value: '8',
      },
    ],
  },

  occupation_field: {
    label: 'occupation_field.occupation_field',
    key: 'occupation_field',
    value: 'occupation',
    options: [
      {
        label: 'occupation_field.bestuur_en_management',
        key: 'bestuur_en_management',
        value: '1',
        options: [
          {
            label: 'occupation_sector.geen_van_onderstaande',
            key: 'geen_van_onderstaande',
            value: '1',
          },
          {
            label: 'occupation_sector.trustkantoren',
            key: 'trustkantoren',
            value: '2',
          },
        ],
      },
      {
        label: 'occupation_field.bouw_en_infrastructuur',
        key: 'bouw_en_infrastructuur',
        value: '2',
        options: [
          {
            label: 'occupation_sector.geen_van_onderstaande',
            key: 'geen_van_onderstaande',
            value: '0',
          },
          {
            label: 'occupation_sector.afvalbeheer_en_sanering',
            key: 'afvalbeheer_en_sanering',
            value: '1',
          },
          {
            label: 'occupation_sector.offshore',
            key: 'offshore',
            value: '3',
          },
          {
            label: 'occupation_sector.watervoorziening_en_riolering',
            key: 'watervoorziening_en_riolering',
            value: '4',
          },
        ],
      },
      {
        label: 'occupation_field.financieel',
        key: 'financieel',
        value: '3',
        options: [
          {
            label: 'occupation_sector.geen_van_onderstaande',
            key: 'nee_geen_van_onderstaande',
            value: '0',
          },
          {
            label: 'occupation_sector.betalingsdiensten_of_vergelijkbaar',
            key: 'betalingsdiensten_of_vergelijkbaar',
            value: '1',
          },
          {
            label: 'occupation_sector.crowdfunding',
            key: 'crowdfunding',
            value: '2',
          },
          {
            label:
              'occupation_sector.handel_in_cryptovaluta_of_initial_coin_offering',
            key: 'handel_in_cryptovaluta_of_initial_coin_offering',
            value: '3',
          },
          {
            label: 'occupation_sector.verzekeringen',
            key: 'verzekeringen',
            value: '4',
          },
        ],
      },
      {
        label: 'occupation_field.handel',
        key: 'handel',
        value: '4',
      },
      {
        label: 'occupation_field.ict_telecom_en_online',
        key: 'ict_telecom_en_online',
        value: '5',
      },
      {
        label: 'occupation_field.horeca',
        key: 'horeca',
        value: '6',
      },
      {
        label: 'occupation_field.industrie',
        key: 'industrie',
        value: '7',
      },
      {
        label: 'occupation_field.kunst_amusement_en_recreatie',
        key: 'kunst_amusement_en_recreatie',
        value: '8',
      },
      {
        label: 'occupation_field.onderwijs_en_overheid',
        key: 'onderwijs_en_overheid',
        value: '9',
      },
      {
        label: 'occupation_field.landbouw_bosbouw_en_visserij',
        key: 'landbouw_bosbouw_en_visserij',
        value: '10',
      },
      {
        label: 'occupation_field.vervoer_transport_en_logistiek',
        key: 'vervoer_transport_en_logistiek',
        value: '11',
      },
      {
        label: 'occupation_field.sport',
        key: 'sport',
        value: '12',
      },
      {
        label: 'occupation_field.vastgoed',
        key: 'vastgoed',
        value: '13',
      },
      {
        label: 'occupation_field.zakelijk_en_commercieel',
        key: 'zakelijk_en_commercieel',
        value: '14',
      },
      {
        label: 'occupation_field.zorg_en_welzijn',
        key: 'zorg_en_welzijn',
        value: '15',
      },
      {
        label: 'occupation_field.anders',
        key: 'anders',
        value: '16',
      },
    ],
  },
}

const Occupation: React.FC<Props> = ({ errors, control, path }) => {
  const { t } = useTranslation()
  const [selected, setSelected] = useState(false)
  const [selectedTwo, setSelectedTwo] = useState(false)
  const [selectedField, setSelectedField] = useState<OptionItem[]>([])
  return (
    <>
      <FormLayout title={t('occupation')}>
        <Controller
          name={`${path}.occupation-status`}
          control={control}
          render={({ field }) => {
            const { onChange, value, ref: _, ...rest } = field
            if (value) setSelected(true)
            return (
              <Select
                {...rest}
                name={`${path}.occupation-status`}
                wrapperClassName={styles.inputWrapperLgSpan4}
                label={t(data.occupation_status.label) + ' *'}
                errors={errors}
                hasEmptyOption={true}
                options={
                  data.occupation_status.options?.map(
                    ({ key, value, label }) => ({
                      key,
                      value,
                      label: t(`${label}`),
                    }),
                  ) ?? []
                }
                value={value}
                onChange={(val) => {
                  if (val) {
                    setSelected(true)
                    onChange(val)
                  }
                }}
              />
            )
          }}
          rules={requiredWithMessage}
        />

        {selected ? (
          <Controller
            name={`${path}.occupation-field`}
            control={control}
            render={({ field }) => {
              const { onChange, value, ref: _, ...rest } = field
              if (value) setSelected(true)
              return (
                <Select
                  {...rest}
                  name={`${path}.occupation-field`}
                  wrapperClassName={styles.inputWrapperLgSpan4}
                  label={t(data.occupation_field.label) + ' *'}
                  errors={errors}
                  hasEmptyOption={true}
                  options={
                    data.occupation_field.options?.map(
                      ({ key, value, label }) => ({
                        key,
                        value,
                        label: t(`${label}`),
                      }),
                    ) ?? []
                  }
                  value={value}
                  onChange={(val) => {
                    if (val) {
                      setSelectedField(
                        data.occupation_field.options?.filter(
                          (e) => e.value === val,
                        ) ?? [],
                      )
                      setSelectedTwo(true)
                      onChange(val)
                    }
                  }}
                />
              )
            }}
            rules={requiredWithMessage}
          />
        ) : null}
        {selectedTwo
          ? selectedField.map((selectedEntry) => {
              return (
                <Controller
                  name={`${path}.occupation-sector`}
                  control={control}
                  render={({ field }) => {
                    const { onChange, value, ref: _, ...rest } = field
                    if (isEmpty(value)) setSelectedTwo(true)

                    return (
                      <Select
                        {...rest}
                        name={`${path}.occupation-sector`}
                        wrapperClassName={styles.inputWrapperLgSpan4}
                        key={selectedEntry.key}
                        label={t('occupation_sector.occupation_sector') + ' *'}
                        hasEmptyOption={true}
                        errors={errors}
                        options={
                          selectedEntry?.options?.map(
                            ({ key, value, label }) => ({
                              key,
                              value,
                              label: t(`${label}`),
                            }),
                          ) ?? []
                        }
                        value={value}
                        onChange={(val) => onChange(val)}
                      />
                    )
                  }}
                  rules={requiredWithMessage}
                />
              )
            })
          : null}
      </FormLayout>
    </>
  )
}

export default Occupation
