import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEnvelopeOpenText,
  faTag,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons'
import './messages.scss'
import React from 'react'

const Messages = (): JSX.Element => (
  <div className="w-full">
    <h1 className="flex items-center font-sans font-bold break-normal text-gray-700 px-2 mb-4 text-xl mt-12 lg:mt-0 md:text-2xl">
      Berichten (1 ongelezen)
    </h1>
    <div className="mx-auto container bg-white dark:bg-gray-800 shadow-sm rounded mb-12">
      <div className="flex flex-col lg:flex-row p-2 lg:p-8 justify-between items-start lg:items-stretch w-full">
        <div className="w-full lg:w-1/3 flex flex-col lg:flex-row items-start lg:items-center">
          <div className="flex items-center">
            <button className="ml-0 actionButton">
              <FontAwesomeIcon icon={faEnvelopeOpenText} />
            </button>
            <button className="actionButton">
              <FontAwesomeIcon icon={faTag} />
            </button>
            <button className="actionButton">
              <FontAwesomeIcon icon={faTrashAlt} />
            </button>
          </div>
        </div>
        <div className="w-full lg:w-2/3 flex flex-col lg:flex-row items-start lg:items-center justify-end">
          <div className="flex items-center lg:border-l lg:border-r border-gray-300 dark:border-gray-200 py-3 lg:py-0 lg:px-6">
            <p className="text-base" id="page-view">
              Bekijken 1 - 6 van 6
            </p>
            <a className="ml-2 border-transparent border cursor-pointer rounded">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-chevron-left"
                width={20}
                height={20}
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <polyline points="15 6 9 12 15 18" />
              </svg>
            </a>
            <a className="border-transparent border rounded focus:outline-none cursor-pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-chevron-right"
                width={20}
                height={20}
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <polyline points="9 6 15 12 9 18" />
              </svg>
            </a>
          </div>
          <div className="lg:ml-6 flex items-center">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary"
              id="inline-full-name"
              type="text"
              placeholder="zoeken..."
            />
          </div>
        </div>
      </div>
      <div className="w-full overflow-x-scroll xl:overflow-x-hidden">
        <table className="min-w-full bg-white dark:bg-gray-800">
          <thead>
            <tr className="w-full h-16 border-gray-200 dark:border-gray-600 border-b-3 py-8 font-bold">
              <th className="pl-8 text-gray-600 dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal">
                <input
                  type="checkbox"
                  className="cursor-pointer relative w-5 h-5 border rounded border-gray-400 dark:border-gray-200 bg-white dark:bg-gray-800 outline-none"
                />
              </th>
              <th className="pr-6 text-left text-sm tracking-normal">
                Bijlagen
              </th>
              <th className="pr-6 text-left text-sm tracking-normal">Datum</th>
              <th className="pr-6 text-left text-sm tracking-normal">Titel</th>
              <th className="pr-6 text-left text-sm tracking-normal">Van</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr className="h-24 border-gray-200 dark:border-gray-200 border-b font-bold">
              <td className="pl-8 pr-6 text-left whitespace-no-wrap text-sm text-gray-800 dark:text-gray-100 tracking-normal">
                <input
                  type="checkbox"
                  className="cursor-pointer relative w-5 h-5 border rounded border-gray-400 dark:border-gray-200 bg-white dark:bg-gray-800 outline-none"
                />
              </td>
              <td className="text-sm pr-6 whitespace-no-wrap tracking-normal">
                <div className="relative w-10">
                  <div className="absolute top-0 right-0 w-5 h-5 mr-2 -mt-1 rounded-full bg-gray-600 text-white flex justify-center items-center text-xs">
                    3
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-file"
                    width={28}
                    height={28}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                    <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                  </svg>
                </div>
              </td>
              <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal">
                02.03.20
              </td>
              <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal">
                Kwartaalbericht 2de kwartaal 2021
              </td>
              <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal">
                Bram van den Berg
              </td>
              <td className="pr-8 relative">
                <div className="dropdown-content mt-8 absolute left-0 -ml-12 shadow-md z-10 hidden w-32">
                  <ul className="bg-white dark:bg-gray-800 shadow rounded py-1">
                    <li className="cursor-pointer text-gray-600 dark:text-gray-400 text-sm leading-3 tracking-normal py-3 hover:bg-primary hover:text-white px-3 font-normal">
                      Edit
                    </li>
                    <li className="cursor-pointer text-gray-600 dark:text-gray-400 text-sm leading-3 tracking-normal py-3 hover:bg-primary hover:text-white px-3 font-normal">
                      Delete
                    </li>
                    <li className="cursor-pointer text-gray-600 dark:text-gray-400 text-sm leading-3 tracking-normal py-3 hover:bg-primary hover:text-white px-3 font-normal">
                      Duplicate
                    </li>
                  </ul>
                </div>
                <button className="text-gray-500 rounded cursor-pointer border border-transparent focus:outline-none">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-dots-vertical dropbtn"
                    width={28}
                    height={28}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <circle cx={12} cy={12} r={1} />
                    <circle cx={12} cy={19} r={1} />
                    <circle cx={12} cy={5} r={1} />
                  </svg>
                </button>
              </td>
            </tr>
            <tr className="h-24 border-gray-200 dark:border-gray-200 border-b">
              <td className="pl-8 pr-6 text-left whitespace-no-wrap text-sm text-gray-800 dark:text-gray-100 tracking-normal">
                <input
                  type="checkbox"
                  className="cursor-pointer relative w-5 h-5 border rounded border-gray-400 dark:border-gray-200 bg-white dark:bg-gray-800 outline-none"
                />
              </td>
              <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal">
                <div className="opacity-40 relative w-10">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-file"
                    width={28}
                    height={28}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                    <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                  </svg>
                </div>
              </td>
              <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal">
                02.03.20
              </td>
              <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal">
                Kwartaalbericht 2de kwartaal 2021
              </td>
              <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal">
                Bram van den Berg
              </td>
              <td className="pr-8 relative">
                <button className="text-gray-500 rounded cursor-pointer border border-transparent focus:outline-none ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-dots-vertical dropbtn"
                    width={28}
                    height={28}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <circle cx={12} cy={12} r={1} />
                    <circle cx={12} cy={19} r={1} />
                    <circle cx={12} cy={5} r={1} />
                  </svg>
                </button>
              </td>
            </tr>
            <tr className="h-24 border-gray-200 dark:border-gray-200 border-b">
              <td className="pl-8 pr-6 text-left whitespace-no-wrap text-sm text-gray-800 dark:text-gray-100 tracking-normal">
                <input
                  type="checkbox"
                  className="cursor-pointer relative w-5 h-5 border rounded border-gray-400 dark:border-gray-200 bg-white dark:bg-gray-800 outline-none"
                />
              </td>
              <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal">
                <div className="opacity-40 relative w-10">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-file"
                    width={28}
                    height={28}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                    <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                  </svg>
                </div>
              </td>
              <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal">
                02.03.20
              </td>
              <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal">
                Kwartaalbericht 2de kwartaal 2021
              </td>
              <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal">
                Bram van den Berg
              </td>
              <td className="pr-8 relative">
                <button className="text-gray-500 rounded cursor-pointer border border-transparent focus:outline-none">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-dots-vertical dropbtn"
                    width={28}
                    height={28}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <circle cx={12} cy={12} r={1} />
                    <circle cx={12} cy={19} r={1} />
                    <circle cx={12} cy={5} r={1} />
                  </svg>
                </button>
              </td>
            </tr>
            <tr className="h-24 border-gray-200 dark:border-gray-200 border-b">
              <td className="pl-8 pr-6 text-left whitespace-no-wrap text-sm text-gray-800 dark:text-gray-100 tracking-normal">
                <input
                  type="checkbox"
                  className="cursor-pointer relative w-5 h-5 border rounded border-gray-400 dark:border-gray-200 bg-white dark:bg-gray-800 outline-none"
                />
              </td>
              <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal">
                <div className="opacity-40 relative w-10">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-file"
                    width={28}
                    height={28}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                    <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                  </svg>
                </div>
              </td>
              <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal">
                02.03.20
              </td>
              <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal">
                Kwartaalbericht 2de kwartaal 2021
              </td>
              <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal">
                Bram van den Berg
              </td>
              <td className="pr-8 relative">
                <button className="text-gray-500 rounded cursor-pointer border border-transparent focus:outline-none">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-dots-vertical dropbtn"
                    width={28}
                    height={28}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <circle cx={12} cy={12} r={1} />
                    <circle cx={12} cy={19} r={1} />
                    <circle cx={12} cy={5} r={1} />
                  </svg>
                </button>
              </td>
            </tr>
            <tr className="h-24 border-gray-200 dark:border-gray-200 border-b">
              <td className="pl-8 pr-6 text-left whitespace-no-wrap text-sm text-gray-800 dark:text-gray-100 tracking-normal">
                <input
                  type="checkbox"
                  className="cursor-pointer relative w-5 h-5 border rounded border-gray-400 dark:border-gray-200 bg-white dark:bg-gray-800 outline-none"
                />
              </td>
              <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal">
                <div className="opacity-50 relative w-10">
                  <div className="absolute top-0 right-0 w-5 h-5 mr-2 -mt-1 rounded-full bg-gray-600 text-white flex justify-center items-center text-xs">
                    1
                  </div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-file"
                      width={28}
                      height={28}
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" />
                      <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                      <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                    </svg>
                  </div>
                </div>
              </td>
              <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal">
                02.03.20
              </td>
              <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal">
                Kwartaalbericht 2de kwartaal 2021
              </td>
              <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal">
                Bram van den Berg
              </td>
              <td className="pr-8 relative">
                <button className="text-gray-500 rounded cursor-pointer border border-transparent focus:outline-none">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-dots-vertical dropbtn"
                    width={28}
                    height={28}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <circle cx={12} cy={12} r={1} />
                    <circle cx={12} cy={19} r={1} />
                    <circle cx={12} cy={5} r={1} />
                  </svg>
                </button>
              </td>
            </tr>
            <tr className="h-24 border-gray-200 dark:border-gray-200 border-b">
              <td className="pl-8 pr-6 text-left whitespace-no-wrap text-sm text-gray-800 dark:text-gray-100 tracking-normal">
                <input
                  type="checkbox"
                  className="cursor-pointer relative w-5 h-5 border rounded border-gray-400 dark:border-gray-200 bg-white dark:bg-gray-800 outline-none"
                />
              </td>
              <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal">
                <div className="opacity-50 relative w-10">
                  <div className="absolute top-0 right-0 w-5 h-5 mr-2 -mt-1 rounded-full bg-gray-600 text-white flex justify-center items-center text-xs">
                    5
                  </div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-file"
                      width={28}
                      height={28}
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" />
                      <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                      <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                    </svg>
                  </div>
                </div>
              </td>
              <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal">
                02.03.20
              </td>
              <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal">
                Kwartaalbericht 2de kwartaal 2021
              </td>
              <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal">
                Bram van den Berg
              </td>
              <td className="pr-8 relative">
                <button className="text-gray-500 rounded cursor-pointer border border-transparent focus:outline-none">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-dots-vertical dropbtn"
                    width={28}
                    height={28}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <circle cx={12} cy={12} r={1} />
                    <circle cx={12} cy={19} r={1} />
                    <circle cx={12} cy={5} r={1} />
                  </svg>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
)

export default Messages
