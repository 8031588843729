import React, { PropsWithChildren } from 'react'

type Props = {
  title: string
  description?: string
  dataTestId?: string
}

const FormLayout: React.FC<PropsWithChildren<Props>> = ({
  dataTestId,
  title,
  description,
  children,
}) => (
  <div
    data-test-id={dataTestId}
    className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6"
  >
    <div className="md:grid md:grid-cols-3 md:gap-6">
      <div className="md:col-span-1 lg:w-80">
        <h3 className="text-lg font-medium leading-6">{title}</h3>
        {description && <p className="mt-1 text-sm">{description}</p>}
      </div>
      <div className="mt-5 md:mt-0 md:col-span-2 space-y-6">
        <div className="grid grid-cols-6 gap-6">{children}</div>
      </div>
    </div>
  </div>
)

export default FormLayout
