import React, { useEffect, useRef } from 'react'
import * as d3 from 'd3'
import './chart.scss'
import useResizeObserver from 'use-resize-observer'
import { TFunction, useTranslation } from 'react-i18next'

export type ChartLinePoint = { year: number; amount: number }

export type ChartLineData = {
  name: string
  values: ChartLinePoint[]
}

type Props = {
  data: ChartLineData[]
  targetAmount: number
  width?: number
  height?: number
  invalidData?: boolean
}

const color = d3.scaleOrdinal(d3.schemeCategory10)
const margin = { top: 50, right: 10, bottom: 50, left: 40 }
const lineOpacity = '0.45'

const renderMultiChart = (
  data: ChartLineData[],
  targetAmount: number,
  ref: React.MutableRefObject<HTMLDivElement | null>,
  width: number,
  height: number,
  invalidData: boolean,
  t: TFunction<string>,
) => {
  // const margin = { top: 50, right: 10, bottom: 50, left: 60 }
  // const lineOpacity = '0.45'

  d3.select(ref.current).select('svg').remove()

  // Add SVG
  const svg = d3
    .select(ref.current)
    .append('svg')
    .attr('width', width + margin.left + margin.right + 'px')
    .attr('height', height + margin.top + margin.bottom + 'px')
    .append('g')
    .attr('transform', `translate(${margin.left}, ${margin.top})`)

  const xScale = d3
    .scaleLinear()
    .domain([
      0,
      ...data.map((line: ChartLineData) =>
        line.values.reduce((max, v) => Math.max(max, v.year), 0),
      ),
    ])
    .range([0, width - margin.left - margin.right])

  const yScale = d3
    .scaleLinear()
    .domain(
      [
        0,
        Math.max(
          ...data.map((line: ChartLineData) =>
            line.values.reduce((max, v) => Math.max(max, v.amount), 0),
          ),
          targetAmount,
        ),
      ].sort(),
    )
    .range([height - margin.top - margin.bottom, 0])

  /* Add Axis into SVG */
  const xAxis = d3.axisBottom(xScale).ticks(0)
  const yAxis = d3.axisLeft(yScale).ticks(0)
  svg
    .append('g')
    .attr('class', 'x axis')
    .attr('stroke-opacity', '0.3')
    .attr('transform', `translate(0, ${height - margin.top - margin.bottom})`)
    .call(xAxis)
  svg
    .append('g')
    .attr('class', 'y axis')
    .attr('stroke-opacity', '0.3')
    .call(yAxis)

  targetAmount &&
    !invalidData &&
    svg
      .append('line')
      .attr('x1', 0)
      .attr('y1', yScale(targetAmount))
      .attr('x2', xScale(data[0].values.length - 1))
      .attr('y2', yScale(targetAmount))
      .style('stroke-width', 3)
      .style('stroke', '#999999')
      .style('stroke-dasharray', '4, 4')

  targetAmount &&
    !invalidData &&
    svg
      .append('text')
      .attr('x', xScale(0))
      .attr('y', yScale(targetAmount))
      .attr('transform', 'translate(' + margin.right + ', ' + -5 + ')')
      .attr('text-anchor', 'start')
      .style('fill', '#000')
      // .style('vertical-align', 'bottom')
      .style('font-size', '10px')
      .text(t<string>('target_amount') + `: € ${targetAmount} `)

  if (invalidData) {
    svg
      .append('text')
      .attr('x', (width - margin.left - margin.right) / 3)
      .attr('y', (height - margin.top - margin.bottom) / 2)
      .attr('fill', '#000')
      .text(t<string>('Invalid data'))
      .style('font-size', '30px')
      .style('opacity', '0.4')
  } else {
    /* Add line into SVG */
    const line = d3
      .line()
      .x(([x, _]) => xScale(x))
      .y(([_, y]) => yScale(y))
      .curve(d3.curveCatmullRom)

    const lines = svg.append('g').attr('class', 'lines')

    lines
      .selectAll('.line-group')
      .data(data)
      .enter()
      .append('g')
      .attr('class', 'line-group')
      .append('path')
      .attr('class', 'line')
      .attr('d', (d) => line(d.values.map((d) => [d.year, d.amount])))
      // .style('stroke', (d, i) => color(i.toString()))
      .style('stroke', (d) => color(d.name))
      .style('opacity', lineOpacity)
  }

  const years = data[0].values[data[0].values.length - 1]?.year
    ? data[0].values[data[0].values.length - 1].year
    : 0

  svg
    .append('g')
    .attr('class', 'x axis')
    .attr('stroke-opacity', '0.3')
    .attr('transform', `translate(0, ${height - margin.top - margin.bottom})`)
    .call(xAxis)
    .append('text')
    .attr('x', `${width - margin.left - margin.right - 10}`)
    .attr('y', 20)
    .attr('text-anchor', 'end')
    .attr('fill', '#000')
    .text(`${years || ''} ${t('years', { count: years })}`)

  svg
    .append('g')
    .attr('class', 'y axis')
    .attr('stroke-opacity', '0.3')
    .call(yAxis)
    .append('text')
    .attr('y', -15)
    .attr('fill', '#000')
    .text(t<string>('amount'))

  if (width <= 500) {
    svg
      .selectAll('mydots')
      .data(data)
      .enter()
      .append('circle')
      .attr('cx', 7)
      .attr('cy', (d, i) => 220 + i * 25) // 100 is where the first dot appears. 25 is the distance between dots
      .attr('r', 4)
      .style('fill', (d) => color(d.name))

    svg
      .selectAll('mylabels')
      .data(data)
      .enter()
      .append('text')
      .attr('x', 15)
      .attr('y', (d, i) => 220 + i * 25) // 100 is where the first dot appears. 25 is the distance between dots
      .style('fill', '#000')
      .text((d) => `${d.name} performance`)
      .attr('text-anchor', 'left')
      .style('alignment-baseline', 'middle')
      .style('font-size', '10px')
  } else {
    svg
      .selectAll('mydots')
      .data(data)
      .enter()
      .append('circle')
      .attr('cx', (d, i) => 10 + i * 160)
      .attr('cy', 230) // 100 is where the first dot appears. 25 is the distance between dots
      .attr('r', 4)
      .style('fill', (d) => color(d.name))

    svg
      .selectAll('mylabels')
      .data(data)
      .enter()
      .append('text')
      .attr('x', (d, i) => 20 + i * 160)
      .attr('y', 230) // 100 is where the first dot appears. 25 is the distance between dots
      .style('fill', '#000')
      .text((d) => t<string>(`${d.name}_performance`))
      .attr('text-anchor', 'left')
      .style('alignment-baseline', 'middle')
      .style('font-size', '10px')
  }
}

const InvestmentPlanChart: React.FC<Props> = ({
  data,
  targetAmount = 0,
  height = 300,
  invalidData = false,
}) => {
  const { t } = useTranslation()
  const svgRef = useRef<HTMLDivElement | null>(null)
  const { width = 1 } = useResizeObserver<HTMLDivElement>({ ref: svgRef })

  useEffect(() => {
    renderMultiChart(data, targetAmount, svgRef, width, height, invalidData, t)
  }, [data, targetAmount, svgRef.current, width, invalidData])
  return <div ref={svgRef} className="w-full col-span-6 overflow-x-auto"></div>
}

export default InvestmentPlanChart
