import React from 'react'
import BaseFormComponentProps from '@/common/components/base-form-component-props'
import ValidationError from '@/common/components/forms/validation-error'
import { SelectOptionType } from '@/core/models/onboarding'

export type RadioOptionType = string | SelectOptionType

type Props = BaseFormComponentProps & {
  options: SelectOptionType[]
}

const RadioGroupInput = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      errors,
      label,
      wrapperClassName = '',
      onChange,
      onBlur,
      name,
      options,
    }: Props,
    ref,
  ) => (
    <div className={wrapperClassName}>
      <label htmlFor={name} className={`block text-sm font-medium`}>
        {label}
      </label>
      <nav className="space-y-3">
        <div className="bg-white rounded-md -space-y-px">
          {options.map((option) => {
            const { value, label } =
              typeof option === 'string'
                ? { value: option, label: option }
                : option
            return (
              <div key={value}>
                <input
                  type="radio"
                  onChange={onChange}
                  onBlur={onBlur}
                  ref={ref}
                  id={`${name}.${value}`}
                  value={value}
                  className="h-4 w-4 mx-4 mt-0.5 cursor-pointer rounded-full items-center justify-center text-primary focus:ring-primary"
                  name={name}
                />
                <label htmlFor={`${name}.${value}`} className="radio-btns">
                  <span className="text-sm font-medium">{label}</span>
                </label>
              </div>
            )
          })}
        </div>
      </nav>
      {errors && <ValidationError errors={errors} name={name} />}
    </div>
  ),
)

export default RadioGroupInput
