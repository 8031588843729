import React, { PropsWithChildren } from 'react'
import {
  InfoPageHeaderModel,
  InfoPageContentModel,
} from '@/core/models/onboarding'
import InfoPageHeaderComponent from './header'
import InfoPageContentComponent from './content'

type Props = {
  wrapperClassName?: string
  header: InfoPageHeaderModel
  content: InfoPageContentModel
}

const InfoPage: React.FC<PropsWithChildren<Props>> = ({
  wrapperClassName,
  header,
  content,
}) => (
  <div className={`info-page ${wrapperClassName}`}>
    <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
      <div className="md:grid">
        <InfoPageHeaderComponent
          title={header.title}
          backgroundColor={header.backgroundColor}
          textColor={header.textColor}
          icon={header.icon}
        />
        <InfoPageContentComponent content={content.content} />
      </div>
    </div>
  </div>
)

export default InfoPage
