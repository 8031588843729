import React, { PropsWithChildren } from 'react'
import './table.scss'

type Props = {
  data: {
    caption?: string
    headers?: {
      name: string
      value: string
      num?: string
    }
    table?: {
      name: string
      value: string
      num?: string
    }[]
  }
}

const Table: React.FC<PropsWithChildren<Props>> = ({ data }) => (
  <>
    <table className="fundTable w-full">
      <caption className="text-left text-sm">{data.caption}</caption>
      <tbody>
        {data.headers && (
          <tr className="text-small">
            <th className="w-1/2">{data.headers.name}</th>
            <th className="w-1/4">{data.headers.num}</th>
            <th className="w-1/4">{data.headers.value}</th>
          </tr>
        )}

        {data.table &&
          data.table.map((t) => (
            <tr className="text-small" key={t.name}>
              <td>{t.name}</td>
              <td className="whitespace-nowrap">{t.num}</td>
              <td className="whitespace-nowrap">{t.value}</td>
            </tr>
          ))}
      </tbody>
    </table>
  </>
)

export default Table
