import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf, faFileDownload } from '@fortawesome/free-solid-svg-icons'
import './documents.scss'
import React from 'react'

const Documents = (): JSX.Element => (
  <div className="w-full">
    <h1 className="flex items-center font-sans font-bold break-normal text-default px-2 mb-4 text-xl mt-12 lg:mt-0 md:text-2xl">
      Documenten
    </h1>
    <div className="mx-auto container bg-white dark:bg-gray-800 shadow-sm rounded mb-12">
      <div className="flex flex-col lg:flex-row p-2 lg:p-8 justify-between items-start lg:items-stretch w-full">
        <div className="w-full lg:w-full flex flex-col lg:flex-row items-start lg:items-center justify-end">
          <div className="flex items-center lg:border-r border-gray-300 dark:border-gray-200 py-3 lg:py-0 lg:px-6">
            <p className="text-base" id="page-view">
              Bekijken 1 - 3 van 3
            </p>
            <a className="ml-2 border-transparent border cursor-pointer rounded">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-chevron-left"
                width={20}
                height={20}
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <polyline points="15 6 9 12 15 18" />
              </svg>
            </a>
            <a className="border-transparent border rounded focus:outline-none cursor-pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-chevron-right"
                width={20}
                height={20}
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <polyline points="9 6 15 12 9 18" />
              </svg>
            </a>
          </div>
          <div className="lg:ml-6 flex items-center">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary"
              id="inline-full-name"
              type="text"
              placeholder="zoeken..."
            />
          </div>
        </div>
      </div>
      <div className="w-full overflow-x-scroll xl:overflow-x-hidden">
        <table className="min-w-full bg-white dark:bg-gray-800">
          <thead>
            <tr className="w-full h-16 border-gray-200 dark:border-gray-600 border-b-3 py-8 font-bold">
              <th className="pr-6 text-left text-sm tracking-normal  pl-6">
                Bestandsnaam
              </th>
              <th className="pr-6 text-left text-sm tracking-normal">
                Grootte
              </th>
              <th className="pr-6 text-left text-sm tracking-normal">
                Aanmaakdatum
              </th>
              <th className="pr-6 text-left text-sm tracking-normal"></th>
            </tr>
          </thead>
          <tbody>
            <tr className="h-24 border-gray-200 dark:border-gray-200 border-b">
              <td className="text-sm pr-6 whitespace-no-wrap text-gray-600 dark:text-gray-100 tracking-normal  pl-6">
                Kwartaalbericht 2de kwartaal 2021.pdf
              </td>
              <td className="text-sm pr-6 whitespace-no-wrap text-gray-600 dark:text-gray-100 tracking-normal">
                327K
              </td>
              <td className="text-sm pr-6 whitespace-no-wrap text-gray-600 dark:text-gray-100 tracking-normal">
                01-07-2021 14:23
              </td>
              <td className="text-sm pr-6 whitespace-no-wrap text-gray-600 dark:text-gray-100 tracking-normal">
                <a href="#" className="mr-4">
                  <FontAwesomeIcon size="2x" icon={faFilePdf} />
                </a>
                <a href="#">
                  <FontAwesomeIcon size="2x" icon={faFileDownload} />
                </a>
              </td>
            </tr>
            <tr className="h-24 border-gray-200 dark:border-gray-200 border-b">
              <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal  pl-6">
                Kwartaalbericht 1ste kwartaal 2021.pdf
              </td>
              <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal">
                327K
              </td>
              <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal">
                02-04-2021 14:23
              </td>
              <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal">
                <a href="#" className="mr-4">
                  <FontAwesomeIcon size="2x" icon={faFilePdf} />
                </a>
                <a href="#">
                  <FontAwesomeIcon size="2x" icon={faFileDownload} />
                </a>
              </td>
            </tr>
            <tr className="h-24 border-gray-200 dark:border-gray-200 border-b">
              <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal  pl-6">
                Notulen Jaarvergardering.pdf
              </td>
              <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal">
                327K
              </td>
              <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal">
                02-03-2021 14:23
              </td>
              <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal">
                <a href="#" className="mr-4">
                  <FontAwesomeIcon size="2x" icon={faFilePdf} />
                </a>
                <a href="#">
                  <FontAwesomeIcon size="2x" icon={faFileDownload} />
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
)

export default Documents
