import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select from '@/portal/components/select-input'
import { data } from '../../../common/services/fund-manager-data'
import Table from '../../components/table'
import TableCard from '../../components/table-card'
import FundLineChart from '@/portal/components/fund-chart'
import {
  chartMockDataInvestments,
  chartMockDataPositions,
} from '../../../common/services/fund-manager-data'
import nlLocale from 'date-fns/locale/nl'
import { format } from 'date-fns'

export type DataTypeFM = {
  month: { label: string; value: number }
  year: { label: string; value: number }
  data: {
    funds: { label: string; value: string }[]
    fund_stats: { name: string; value: string }[]
    details_data: {
      title: string
      investments: {
        caption: string
        table: {
          name: string
          value: string
        }[]
      }
      cash: {
        caption: string
        table: {
          name: string
          value: string
        }[]
      }
      nav: {
        caption: string
        table: {
          name: string
          value: string
        }[]
      }
    }
    fund_data: {
      title: string
      preferred: {
        caption: string
      }
      administrator: {
        table: { name: string; value: string }[]
      }
      address: {
        caption: string
        table: { name: string; value: string }[]
      }
    }
    total: {
      title: string
      overview: {
        caption: string
        table: { name: string; value: string }[]
      }
    }
    monthly: {
      title: string
      tables: Record<string, unknown>
      month_overview: {
        caption: string
        headers: { name: string; num: string; value: string }
        table: {
          name: string
          value: string
          num: string
        }[]
      }
    }
    fund_portfolio: {
      title: string
      market_instrument: {
        headers: { name: string; num: string; value: string }
        table: {
          name: string
          num: string
          value: string
        }[]
      }
    }
  }
}

const FundManagerDashboard = (): JSX.Element => {
  const { t } = useTranslation()
  const fundsOptions = [
    { label: 'Profitable fund', value: 'PF' },
    { label: 'Other fund', value: 'OF' },
  ]

  const monthsSelectOptions = data.map((item: DataTypeFM, index) => {
    return {
      key: [item.year.value, item.month.value].join('-'),
      value: index.toString(),
      label: format(
        new Date(item.year.value, item.month.value - 1, 1),
        'MMM yyyy',
        { locale: nlLocale },
      ),
    }
  })
  const [selectedMonth, setSelectedMonth] = useState<number>(
    new Date().getMonth() + 1,
  )
  const [selectedYear, setSelectedYear] = useState<number>(
    new Date().getFullYear(),
  )
  const [filteredData, setFilteredData] = useState<DataTypeFM | undefined>(
    data[data.length - 1],
  )
  // filters the data in the view
  useEffect(() => {
    const fltrd = data.find(
      (d) => d.month.value === selectedMonth && d.year.value === selectedYear,
    )

    setFilteredData(fltrd)
  }, [selectedMonth, selectedYear])

  return (
    <div className="space-y-4 mb-10 md:pt-4 w-full">
      <h1 className="font-sans font-bold break-normal text-default mb-4 text-xl mt-12 lg:mt-0 md:text-2xl">
        Dashboard
      </h1>
      <div className="md:flex justify-between">
        <div className="sm:grid md:flex md:flex-row md:gap-4 md:items-center">
          <Select
            name="select-month"
            options={monthsSelectOptions}
            wrapperClassName="w-32 block"
            onChange={(value) => {
              const index = parseInt(value || '0')
              data[index] && setSelectedMonth(data[index].month.value)
              data[index] && setSelectedYear(data[index].year.value)
            }}
          />
        </div>
        <div className="sm:grid md:flex md:flex-row md:gap-4 md:justify-items-end md:items-center">
          <h3 className="mx-2 sm:my-2 sm:text-left md:text-right ">
            Kies fonds:
          </h3>

          <Select name="select-month" options={fundsOptions} />
        </div>
      </div>

      {filteredData && (
        <>
          <div className="grid sm:grid-cols-2 md:grid-cols-4 w-full gap-4">
            {filteredData.data.fund_stats.map((d) => {
              return (
                <TableCard key={d.name}>
                  <div className="font-light text-sm">{d.name}</div>
                  <div className="text-lg">{d.value}</div>
                </TableCard>
              )
            })}
          </div>
          <div className="grid md:grid-cols-2 w-full gap-4">
            <TableCard title={filteredData.data.details_data.title}>
              <Table data={filteredData.data.details_data.investments} />
              <Table data={filteredData.data.details_data.cash} />
              <Table data={filteredData.data.details_data.nav} />
            </TableCard>

            <TableCard title={filteredData.data.fund_data.title}>
              <Table data={filteredData.data.fund_data.preferred} />
              <Table data={filteredData.data.fund_data.administrator} />
              <Table data={filteredData.data.fund_data.address} />
            </TableCard>

            <TableCard title={filteredData.data.total.title}>
              <Table data={filteredData.data.total.overview} />
            </TableCard>

            <TableCard title={filteredData.data.monthly.title}>
              <Table data={filteredData.data.monthly.month_overview} />
            </TableCard>
          </div>
          <div className="grid w-full gap-4">
            <TableCard>
              <FundLineChart
                data={chartMockDataPositions}
                dataArea={chartMockDataInvestments}
              />
            </TableCard>

            <TableCard title={filteredData.data.fund_portfolio.title}>
              <Table
                data={filteredData.data.fund_portfolio.market_instrument}
              />
            </TableCard>
          </div>
        </>
      )}
    </div>
  )
}

export default FundManagerDashboard
