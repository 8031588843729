import React, { useCallback, useEffect } from 'react'
import { useNavigate, useMatch } from 'react-router-dom'
import Page from '@/portal/views/onboarding/page'
import FormLayout from '@/portal/components/form-layout'
import TextInput from '@/portal/components/text-input'
import { Controller, useForm, ValidationRule } from 'react-hook-form'
import styles from './onboarding.module.scss'
import Switch from '@/portal/components/switch'
import {
  FormFieldModel,
  OnboardingData,
  OnboardingFlow,
  PageModel,
  RadioGroupModel,
  SelectModel,
  SwitchModel,
  TextInputModel,
  ViewModel,
} from '@/core/models/onboarding'
import { useMutation, useQuery } from 'react-query'
import axios, { AxiosResponse } from 'axios'
import Spinner from '@/common/components/spinner'
import Expand from '@/portal/components/expand'
import RadioGroupInput from '@/portal/components/radio-group'

import PersonalDetails from '@/portal/views/onboarding/personal-details'
import PersonalDetails2 from '@/portal/views/onboarding/personal-details-2'
import BankDetails from '@/portal/views/onboarding/bank-details'
import Identification from '@/portal/views/onboarding/identification'
import onboardingFlow from '@/core/models/onboarding-flow'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import Select from '@/portal/components/select-input'
import InfoPage from '../../components/info-page'
import InvestmentPlan from './investment-plan'
import Occupation from '@/portal/views/onboarding/occupation'
import { fullListOfCountries } from '@/core/models/countries'
import Payment from './payments'
import ContraAccount from './contra-account'
import FinalPage from './final-page'
import { labelWithRequiredCheck } from '@/portal/utils/form'

const Onboarding: React.FC = () => {
  const match = useMatch('/onboarding/:page') ?? { params: { page: '' } }
  const history = useNavigate()
  const { t } = useTranslation()

  const {
    register,
    handleSubmit,
    control,
    getValues,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  })

  // const { isLoading, data } = useQuery(
  //   'fetchCurrentRegistrationFlow',
  //   (): Promise<AxiosResponse<OnboardingFlow>> =>
  //     axios.get('onboarding-flows', {
  //       transformResponse: (data: string) =>
  //         JSON.parse(data, (key, value) => {
  //           if (value === '{{fullListOfCountries}}') {
  //             return fullListOfCountries
  //           }
  //           return value
  //         }),
  //     }),
  // )

  const {
    isLoading,
    data: { data: { data } } = { data: {} },
    refetch,
  } = useQuery(
    'fetchOnboardingData',
    (): Promise<AxiosResponse<OnboardingData>> => {
      return axios.get('onboardings')
    },
  )

  useEffect(() => {
    reset(data)
  }, [data])

  // const pages = data?.data.pages
  const pages = onboardingFlow.pages

  const mutateOnboardingData = useMutation(
    (values: { [key: string]: any }) => axios.post('/onboardings', values),
    {
      onSuccess: () => {
        if (page?.nextRoute) {
          history(`/onboarding/${page.nextRoute}`)
        }
      },
    },
  )

  const onSubmit = () => {
    if (isEmpty(errors)) {
      const pageName = page!.name
      const values = {
        [pageName]: getValues()[pageName],
        onboardingStep: pageName,
      }
      mutateOnboardingData.mutate(values)
    }
  }

  const renderPredefinedContent = (name: string, path: string[]) => {
    const key = path.join('.')
    switch (name) {
      case 'personal-details':
        return (
          <PersonalDetails
            register={register}
            errors={errors}
            watch={watch}
            setValue={setValue}
            control={control}
            path={path[0]}
            key={key}
          />
        )
      case 'personal-details-2':
        return (
          <PersonalDetails2
            path={path[0]}
            key={key}
            register={register}
            errors={errors}
            control={control}
          />
        )
      case 'payment':
        return (
          <Payment
            key={key}
            register={register}
            errors={errors}
            watch={watch}
          />
        )
      case 'bank-details':
        return (
          <BankDetails
            register={register}
            errors={errors}
            key={key}
            path={path[0]}
          />
        )
      case 'investment-plan':
        return (
          <InvestmentPlan
            register={register}
            watch={watch}
            setValue={setValue}
            errors={errors}
            key={key}
            path={path[0]}
          />
        )
      case 'contra-account':
        return (
          <ContraAccount
            register={register}
            errors={errors}
            setValue={setValue}
            key={key}
            path={path[0]}
          />
        )
      case 'occupation':
        return (
          <Occupation
            register={register}
            watch={watch}
            setValue={setValue}
            errors={errors}
            key={key}
            control={control}
            path={path[0]}
          />
        )
      case 'identification':
        return <Identification key={key} path={path[0]} />
      case 'thank-you':
        return <FinalPage key={key} />
      default:
        break
    }
  }

  const render = useCallback(
    (path: string[]) => (model: ViewModel) => {
      const newPath = [...path, model.name ?? '']
      const key = newPath.join('.')

      switch (model.type) {
        case 'Page':
          return (
            <Page
              {...model}
              key={key}
              children={model.children.map(render(newPath))}
            />
          )
        case 'PredefinedContent':
          return renderPredefinedContent(model.name, path)
        case 'FormLayout':
          return (
            <FormLayout
              title={t(model.title)}
              description={model.description ? t(model.description) : undefined}
              key={key}
              children={model.children && model.children.map(render(newPath))}
            />
          )
        case 'TextInput': {
          return (
            <TextInput
              label={labelWithRequiredCheck(model)}
              key={key}
              errors={errors}
              wrapperClassName={styles.inputWrapperBasic}
              {...register(key, model.validation)}
            />
          )
        }
        case 'Switch': {
          return (
            <Controller
              {...model}
              control={control}
              defaultValue={false}
              rules={model.validation}
              key={key}
              render={({ field }) => {
                const { ref: _, ...rest } = field
                return (
                  <Switch
                    {...rest}
                    name={key}
                    errors={errors}
                    label={labelWithRequiredCheck(model)}
                    wrapperClassName="col-span-6 sm:col-span-4"
                  />
                )
              }}
            />
          )
        }
        case 'Expand': {
          return (
            <Expand
              {...model}
              additionalInfo={t(model.additionalInfo)}
              wrapperClassName={styles.inputWrapperBasic}
              key={key}
              children={model.children && model.children.map(render(newPath))}
            />
          )
        }

        case 'RadioGroup': {
          return (
            <RadioGroupInput
              {...model}
              label={labelWithRequiredCheck(model)}
              options={(Array.isArray(model.options) ? model.options : []).map(
                ({ value, label }) => ({
                  value,
                  label: t(`${label}`),
                }),
              )}
              errors={errors}
              wrapperClassName={styles.inputWrapperBasic}
              {...register(key, model.validation)}
              key={key}
            />
          )
        }

        case 'Select': {
          return (
            <Controller
              {...model}
              name={`${path[0]}.${model.name}`}
              defaultValue={
                model.hasEmptyOption ? undefined : model.options[0]?.value
              }
              control={control}
              rules={model.validation}
              key={key}
              render={({ field }) => {
                const { ref: _, ...rest } = field
                return (
                  <>
                    <Select
                      {...rest}
                      label={labelWithRequiredCheck(model)}
                      /* TODO: memoize translated options */
                      options={model.options.map((o) => ({
                        value: o.value,
                        label: t(`${o.label}`),
                      }))}
                      errors={errors}
                      wrapperClassName={styles.inputWrapperBasic}
                      isClearable={true}
                      isSearchable={true}
                      hasEmptyOption={model.hasEmptyOption}
                    />
                  </>
                )
              }}
            />
          )
        }

        case 'InfoPage': {
          return (
            <InfoPage
              {...model}
              wrapperClassName={styles.inputWrapperBasic}
              key={key}
            />
          )
        }

        default:
          return null
      }
    },
    [errors],
  )

  const page: PageModel | undefined = pages?.find(
    (p: PageModel) => match && p.route === (match.params.page! ?? ''),
  )

  if (isLoading || !page) return <Spinner />

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-test-id={`page-${page.route}`}>
      {render([])(page)}
    </form>
  )
}

export default Onboarding
