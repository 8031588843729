import React from 'react'
import '../../app.scss'
import { themeState } from '@/common/state/atoms/theme-state'
import Spinner from '@/common/components/spinner'
import { useRecoilState } from 'recoil'
import { ThemeConfig } from '@/admin/models/theme'
import { useQuery } from 'react-query'
import axios, { AxiosResponse } from 'axios'
import Logo from '../../../common/components/logo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBullseye,
  faCheckCircle,
  faShieldVirus,
  faUser,
} from '@fortawesome/free-solid-svg-icons'
import { useMsal } from '@azure/msal-react'
import { IPublicClientApplication } from '@azure/msal-browser'
import { loginRequest, signupRequest } from '@/portal/msal-config'
import { useTranslation } from 'react-i18next'
import setThemeCSS from '@/common/services/set-theme-css'

function handleLogin(instance: IPublicClientApplication) {
  instance.loginRedirect(loginRequest).catch((e) => {
    console.error(e)
  })
}

function handleSignup(instance: IPublicClientApplication) {
  instance.loginRedirect(signupRequest).catch((e) => {
    console.error(e)
  })
}

const Home: React.FC = () => {
  const [theme, setTheme] = useRecoilState<ThemeConfig>(themeState)
  const { instance } = useMsal()
  const { t } = useTranslation()
  const fetchTheme = useQuery(
    'fetchLatestTheme',
    (): Promise<AxiosResponse<ThemeConfig>> =>
      axios.get('/customization/latest'),
    {
      onSuccess: (data) => {
        const theme = data.data
        setTheme(theme)
        setThemeCSS(theme)
      },
    },
  )
  if (fetchTheme.isLoading || !theme) return <Spinner />

  return (
    <div className="App flex flex-col justify-center min-h-screen ">
      <main className="w-full md:w-2/3 lg:w-1/2 text-gray-700 mx-auto bg-white px-5 pt-10 pb-10 shadow-sm rounded-md">
        <Logo className="lg mx-auto my-2" />
        <header className="text-4xl font-bold py-8 mb-8 border-b border-gray-200">
          <h1>Welcome to {theme.name}</h1>
        </header>
        <div className="relative grid grid-cols-2 gap-6 pb-5">
          <section className="grid grid-cols-1 grid-rows-3 grid">
            <h2 className="text-2xl">New Account?</h2>
            <p className="text-xs py-2">Sign Up here ↓</p>
            <button
              onClick={() => handleSignup(instance)}
              className="xs:w-2/3 w-full justify-center py-2 px-2 lg:px-10 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary nowrap"
            >
              {t('signup')}
            </button>
          </section>
          <section className="w-full grid grid-cols-1 grid-rows-3">
            <h2 className="text-2xl">Already a User?</h2>
            <p className="text-xs py-2">Log In here ↓</p>
            <button
              onClick={() => handleLogin(instance)}
              className="xs:w-2/3 w-full justify-center py-2 px-2 lg:px-10 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-secondary nowrap"
            >
              {t('login')}
            </button>
          </section>
        </div>
        <div>
          <h2 className="py-5 text-2xl">How does it work?</h2>
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-3">
            <div>
              <FontAwesomeIcon size="lg" className="mr-2 mb-2" icon={faUser} />
              <h2 className="text-xs font-bold">
                Sign Up & Personal Information
              </h2>
              <p className="text-xs py-2 opacity-40">
                Let us know a little bit about yourself
              </p>
            </div>
            <div>
              <FontAwesomeIcon
                size="lg"
                className="mr-2 mb-2"
                icon={faShieldVirus}
              />
              <h2 className="text-xs font-bold">Risk and Investment Profile</h2>
              <p className="text-xs py-2 opacity-40">
                Let’s talk about the details
              </p>
            </div>
            <div>
              <FontAwesomeIcon
                size="lg"
                className="mr-2 mb-2"
                icon={faBullseye}
              />
              <h2 className="text-xs font-bold">Target</h2>
              <p className="text-xs py-2 opacity-40">
                Show selected funds based on risk profile
              </p>
            </div>
            <div>
              <FontAwesomeIcon
                size="lg"
                className="mr-2 mb-2"
                icon={faCheckCircle}
              />
              <h2 className="text-xs font-bold">Confirmation</h2>
              <p className="text-xs py-2 opacity-40">Congratulations!</p>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default Home
