import { atom } from 'recoil'
import { InvestmentPlanConfig } from '@/core/models/investment-plan-config'

export const investmentPlanState = atom<InvestmentPlanConfig>({
  key: 'investmentPlan',
  default: {
    defaultInvestmentPeriod: 0,
    targetAmountDefault: 0,
    initialDepositDefault: 0,
    monthlyDepositDefault: 0,
    performanceLevels: [
      { name: 'low', value: 0 },
      { name: 'optimal', value: 0 },
      { name: 'high', value: 0 },
    ],
  },
})
