import React from 'react'
import { FilePond } from 'react-filepond'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import 'filepond/dist/filepond.min.css'
import axios from 'axios'
import './identification.scss'
import { useTranslation } from 'react-i18next'
import { Config } from '../../../../config'

type Props = {
  path: string
}
const Identification: React.FunctionComponent<Props> = ({ path }) => {
  const { t } = useTranslation()

  const fileUploadConfig = {
    instantUpload: false,
    process: {
      url: `${Config.ApiBaseUrl}/customization/upload`,
      headers: {
        Authorization: axios.defaults.headers.common['Authorization'],
      },
    },
    load: {
      url: `${Config.ThemeImageDir}/`,
    },
    revert: {
      url: `${Config.ApiBaseUrl}/customization/upload`,
      headers: {
        Authorization: axios.defaults.headers.common['Authorization'],
      },
    },
  }

  return (
    <div className="identification">
      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6">
              {t('identification')}
            </h3>
            <p className="mt-1 text-sm">{t('passport_identification')}</p>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <FilePond
              className="file-pond-main"
              allowMultiple={true}
              server={fileUploadConfig}
              credits=""
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Identification
