import React, { useEffect, useState } from 'react'
import {
  FieldErrors,
  FieldValues,
  UseFormRegister,
  UseFormWatch,
} from 'react-hook-form'
import TextInput from '../../components/text-input'
import FormLayout from '../../components/form-layout'
import styles from './onboarding.module.scss'
import { useTranslation } from 'react-i18next'
import { regexPositiveNumbers } from '@/core/regex'
import Switch from '@/portal/components/switch'
import { useQuery } from 'react-query'
import axios from 'axios'
import { InvestmentOrderDto } from '@/core/models/investment-order.dto'
import {
  PaymentStatusType,
  getPaymentStatusType,
} from '@/core/models/payment-status-code'
import Spinner from '@/common/components/spinner'
import { useRecoilState } from 'recoil'
import { ThemeConfig } from '../../../admin/models/theme'
import { themeState } from '../../../common/state/atoms/theme-state'

type Props = {
  register: UseFormRegister<FieldValues>
  errors: FieldErrors
  watch: UseFormWatch<FieldValues>
}

const Payment: React.FC<Props> = ({ register, errors, watch }) => {
  const [theme, _] = useRecoilState<ThemeConfig>(themeState)
  const { t } = useTranslation()
  const [switchOn, setSwitchOn] = useState(false)
  const [disableButton, setDisableButton] = useState(true)
  const [lastTransactionRetryCount, setLastTransactionRetryCount] = useState(0)
  const [lastTransactionStatusType, setLastTransactionStatusType] =
    useState<PaymentStatusType | null>(null)
  const [paymentInitialDeposit, paymentMonthlyDeposit] = watch([
    'paymentInitialDeposit',
    'paymentMonthlyDeposit',
  ])
  const maxTransactionRetryCount = 6

  useEffect(() => {
    if (
      paymentInitialDeposit &&
      paymentInitialDeposit !== undefined &&
      !isNaN(paymentInitialDeposit)
    ) {
      setDisableButton(false)
      setLastTransactionStatusType(null)
    } else {
      setDisableButton(true)
    }
  }, [paymentInitialDeposit])

  const { refetch: getRedirectUrlQuery } = useQuery<{ data: string }>(
    'getRedirectUrl',
    async () => {
      return await axios.get(`/payments/payment-url`)
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
    },
  )

  const { refetch: createOrderQuery } = useQuery<InvestmentOrderDto>(
    'createOrder',
    async () => {
      const res = await axios.post<InvestmentOrderDto>(`/investment-orders`, {
        initialDeposit: +paymentInitialDeposit * 100,
        monthlyDeposit: +paymentMonthlyDeposit * 100,
      })
      return res.data
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
    },
  )

  const { refetch: refetchPaymentLastTransaction } = useQuery(
    'getLastTransaction',
    async () => {
      return await axios.get(`/payments/last-transaction`)
    },
    {
      onSuccess: (data) => {
        const paymentStatusType = getPaymentStatusType(data.data.statusCode)
        if (
          paymentStatusType === 'pending' &&
          lastTransactionRetryCount < maxTransactionRetryCount
        ) {
          setLastTransactionRetryCount(lastTransactionRetryCount + 1)
          setTimeout(refetchPaymentLastTransaction, 500)
        }
        setLastTransactionStatusType(paymentStatusType)
      },
    },
  )

  const onCheckoutClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    const { data: _order } = await createOrderQuery()
    const res = await getRedirectUrlQuery()
    if (res.data?.data) {
      window.location.href = res.data.data
    }
  }

  if (lastTransactionStatusType === 'pending') {
    return (
      <>
        <FormLayout title={t('payment')}>
          {lastTransactionRetryCount < maxTransactionRetryCount ? (
            <Spinner />
          ) : (
            <div
              className={`${styles.inputWrapperBasic} w-full px-4 py-2 text-red-700 text-center border-1 border-red-700`}
            >
              {t('payment_status.retry')}
            </div>
          )}
        </FormLayout>
      </>
    )
  }

  return (
    <>
      <FormLayout title={t('payment')}>
        {lastTransactionStatusType === 'failed' && (
          <div
            className={`${styles.inputWrapperBasic} px-4 py-2 text-red-700 text-center border-1 border-red-700`}
          >
            {t('payment_status.failed_or_canceled_retry')}
          </div>
        )}
        {lastTransactionStatusType === 'cancelled' && (
          <div
            className={`${styles.inputWrapperBasic} px-4 py-2 text-red-700 text-center border-1 border-red-700`}
          >
            {t('payment_status.failed_or_canceled_retry')}
          </div>
        )}
        <div className={`${styles.inputWrapperLgSpan4} flex items-end pb-6`}>
          <img
            src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fcdn.freebiesupply.com%2Flogos%2Flarge%2F2x%2Fideal-logo-png-transparent.png&f=1&nofb=1"
            width="50"
            height="00"
          />
          <p className="text-sm px-2 py-2">{t('payment_method_ideal')}</p>
        </div>

        <TextInput
          wrapperClassName={`${styles.inputWrapperLgSpan4} lg:col-span-3`}
          label={t('initial_deposit') + ' *'}
          labelClassName={styles.labelName}
          {...register('paymentInitialDeposit', {
            required: `${t('required_field')}`,
            pattern: {
              value: regexPositiveNumbers,
              message: `${t('invalid_number_format')}`,
            },
          })}
          errors={errors}
        />
        <Switch
          name="automatic_debit"
          value={switchOn}
          label={t('allow_automatic_debit')}
          onChange={setSwitchOn}
          wrapperClassName={styles.inputWrapperLgSpan4}
        />
        <p className={`${styles.inputWrapperLgSpan4} text-xs`}>
          {t('automatic_debit_description', { company: theme.name })}
        </p>

        {switchOn && (
          <TextInput
            wrapperClassName={`${styles.inputWrapperLgSpan4} lg:col-span-3`}
            label={t('monthly_deposit') + ' *'}
            labelClassName={styles.labelName}
            disabled={!switchOn}
            {...register('paymentMonthlyDeposit', {
              required: `${t('required_field')}`,
              pattern: {
                value: regexPositiveNumbers,
                message: `${t('invalid_number_format')}`,
              },
            })}
            errors={errors}
          />
        )}
        <button
          className={`col-span-6 lg:col-span-4 flex bg-primary items-center justify-center rounded-lg py-2 hover:bg-secondary ${
            disableButton ? 'opacity-70 hover:bg-primary' : ''
          }`}
          onClick={onCheckoutClick}
          disabled={disableButton}
        >
          <div className=" px-4 text-gray-100">
            <h1 className="text-sm font-medium text-center">
              {t('payment_button_description')}
            </h1>
          </div>
        </button>
      </FormLayout>
    </>
  )
}

export default Payment
