import React from 'react'
import {
  FieldErrors,
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form'
import TextInput from '../../components/text-input'
import FormLayout from '../../components/form-layout'
import styles from './onboarding.module.scss'
import { useTranslation } from 'react-i18next'
import IBAN from 'iban'
import { useQuery } from 'react-query'
import axios from 'axios'
import { PaymentStatusCode } from '@/core/models/payment-status-code'

type Props = {
  register: UseFormRegister<FieldValues>
  errors: FieldErrors
  setValue: UseFormSetValue<FieldValues>
  path: string
}

const ContraAccount: React.FC<Props> = ({
  register,
  errors,
  setValue,
  path,
}) => {
  const { t } = useTranslation()
  useQuery(
    'getLastTransaction',
    async () => {
      return await axios.get(`/payments/last-transaction`)
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const transaction = data.data

        if (transaction.statusCode === PaymentStatusCode.Success) {
          setValue(`${path}.contraAccountIban`, transaction.iban)
          setValue(`${path}.contraAccountName`, transaction.customerName)
        }
      },
    },
  )

  return (
    <>
      <FormLayout
        description={t('contra_account_description')}
        title={t('contra_account')}
      >
        <TextInput
          wrapperClassName={styles.inputWrapperLgSpan4}
          label={t('account_register') + ' *'}
          labelClassName={styles.labelName}
          {...register(`${path}.contraAccountName`, {
            required: `${t('required_field')}`,
          })}
          errors={errors}
        />

        <TextInput
          wrapperClassName={styles.inputWrapperLgSpan4}
          label={t('iban') + ' *'}
          labelClassName={styles.labelName}
          {...register(`${path}.contraAccountIban`, {
            required: `${t('required_field')}`,
            validate: (value) => IBAN.isValid(value) || `${t('invalid_iban')}`,
          })}
          errors={errors}
        />
      </FormLayout>
    </>
  )
}

export default ContraAccount
