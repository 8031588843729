import React from 'react'

type Props = {
  type: 'button' | 'submit' | 'reset' | undefined
  onClick?: () => void
  className: string
  title: string
  value?: string | ReadonlyArray<string> | number | undefined
  dataTestId?: string
}
const Button = ({
  type,
  onClick,
  className,
  title,
  value,
  dataTestId,
}: Props): JSX.Element => (
  <button
    data-test-id={dataTestId}
    type={type}
    value={value}
    onClick={onClick}
    className={className}
  >
    {title}
  </button>
)

export default Button
