import React, { ElementType } from 'react'
import BaseFormComponentProps from '@/common/components/base-form-component-props'
import { Switch as HeadlessSwitch } from '@headlessui/react'
import './switch.scss'

type Props = Omit<BaseFormComponentProps, 'onBlur' | 'ref' | 'onChange'> & {
  value: boolean
  wrapperTag?: ElementType
  onChange: (value: boolean) => void
}
const Switch = ({
  wrapperClassName = '',
  value,
  onChange,
  label,
  wrapperTag = 'div',
}: Props): JSX.Element => (
  <HeadlessSwitch.Group
    as={wrapperTag}
    className={`flex items-center ${wrapperClassName}`}
  >
    <HeadlessSwitch
      checked={value}
      onChange={onChange}
      className={`${value ? 'bg-secondary' : 'bg-gray-200'} switch`}
    >
      <span
        aria-hidden="true"
        className={`${
          value ? 'translate-x-5' : 'translate-x-0'
        } pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
      />
    </HeadlessSwitch>
    <HeadlessSwitch.Label as="span" className="ml-3">
      <span className="text-sm font-medium">{label}</span>
    </HeadlessSwitch.Label>
  </HeadlessSwitch.Group>
)

export default Switch
