import React from 'react'

type Props = {
  title: string
  icon: string
  textColor?: string
  backgroundColor?: string
}

const InfoPageHeader: React.FC<Props> = ({
  title,
  icon,
  backgroundColor = '#E7EBEF',
  textColor = '#6B7280',
}) => (
  <div
    style={{ backgroundColor, color: textColor }}
    className="w-full py-5 rounded-lg"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="block m-auto text-center h-40 w-40"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
        d={icon}
      />
    </svg>
    <h1 className="text-3xl font-medium text-center m-auto mb-3 block ">
      {title}
    </h1>
  </div>
)

export default InfoPageHeader
