import BaseFormComponentProps from '@/common/components/base-form-component-props'
import React, { useEffect, useState } from 'react'

import ReactSelect from 'react-select'
import './select.scss'
import { SelectOptionType } from '@/core/models/onboarding'
import { useTranslation } from 'react-i18next'
import ValidationError from '@/common/components/forms/validation-error'

type Props = Omit<BaseFormComponentProps, 'onBlur' | 'ref' | 'onChange'> & {
  isSearchable?: boolean
  isClearable?: boolean
  options: SelectOptionType[]
  hasEmptyOption?: boolean
  onChange?: (value: string | null) => void
  value?: string
}

const Select: React.FC<Props> = ({
  wrapperClassName,
  errors,
  label,
  labelClassName = '',
  name,
  onChange,
  options,
  isSearchable = false,
  isClearable = false,
  hasEmptyOption = false,
  value,
}) => {
  const { t } = useTranslation()
  if (!Array.isArray(options)) return null

  const computedValue =
    !hasEmptyOption && !value
      ? options[0]
      : options.find((o) => o.value === value)
  const [currentValue, setCurrentValue] = useState(computedValue)

  useEffect(() => {
    const newValue = options.find((o) => o.value === value)
    setCurrentValue(newValue)
  }, [value])

  useEffect(() => {
    onChange && computedValue?.value && onChange(computedValue.value)
  }, [computedValue?.value])

  return (
    <div className={wrapperClassName} data-test-id={name}>
      <label
        htmlFor={name}
        className={`block text-sm font-medium pb-1 ${labelClassName}`}
      >
        {label}
      </label>
      <ReactSelect
        menuPortalTarget={document.body}
        data-test-id={name}
        className="text-sm"
        classNamePrefix="sl-select"
        value={currentValue}
        isClearable={isClearable}
        isSearchable={isSearchable}
        name={name}
        options={options}
        placeholder={t('make_a_choice')}
        onChange={(val) => {
          onChange && onChange(val?.value ?? null)
        }}
      />
      {errors && <ValidationError errors={errors} name={name} />}
    </div>
  )
}

export default Select
