export default {
  translation: {
    years_zero: '',
    years_one: 'Jaar',
    years_other: 'Jaren',
    payment_method_ideal: 'Uw betaalmethode is iDeal',
    payment_button_description:
      'Klik hier om verder te gaan met eerste storting en de maandelijkse automatische incasso.',
    logout: 'Uitloggen',
    account_details: 'Account Details',
    please_select: 'Selecteer één van de volgende opties',
    account_name: 'Account naam',
    next: 'Volgende',
    previous: 'Vorige',
    date_and_place_of_birth: 'Geboortedatum en geboorteplaats',
    date: 'Datum',
    country: 'Land',
    city: 'Plaats',
    citizenship_and_tax_residence: 'Nationaliteit en fiscaal inwoner',
    citizenship: 'Nationaliteit',
    tax_residence_in_this_country: 'Fiscaal inwoner van dit land',
    tax_country: 'Land waar u belasting betaald',
    tin: 'Burgerservice nummer of TIN (Tax Identificatie Nummer)',
    citizenship_other: 'Andere nationaliteit',
    personal_info: 'Persoonlijke informatie',
    first_name: 'Voornaam',
    last_name: 'Achternaam',
    residence_address: 'Woonadres',
    description_permanent_address:
      'Geef het permanente adres op waar u ook uw post ontvangt.',
    country_region: 'Land / Regio',
    zip_postal: 'Postcode',
    street_number: 'Huisnummer',
    street_number_ext: 'Toevoeging',
    street_name: 'Straatnaam',
    mobile_phone: 'Mobiele telefoon',
    sms_verification:
      'Voor bepaalde acties zullen wij u een verificatie code sturen per sms.',
    country_code: 'Land code',
    identification: 'Identificatie',
    passport_identification:
      'Als onderdeel van het registratie process zijn we verplicht om een kopie van uw paspoort of identiteitsbewijs te vragen, zodat we uw identiteit kunnen verifiëren.',
    username: 'Gebruikersnaam',
    password: 'Wachtwoord',
    forgotten_password: 'Wachtwoord vergeten?',
    login: 'Inloggen',
    warning_invalid_username_or_password:
      'Foute gebruikersnaam en wachtwoord combinatie.',
    email: 'E-mail',
    register: 'Registreer',
    required_fields_note: 'Velden met een * zijn verplicht',
    required_field: 'Dit veld is verplicht',
    invalid_email_format: 'Ongeldige email adres',
    invalid_text_format: 'Ongeldige tekstindeling',
    invalid_number_format: 'Ongeldige getalnotatie',
    invalid_input: 'Ongeldige invoer',
    invalid_tin_number_format: 'Ongeldig belastingidentificatienummer',
    invalid_iban: 'Ongeldig IBAN-formaat',
    password_too_weak: 'Wachtwoord is te zwak',
    countries: {
      nl: 'Nederland',
      be: 'België',
      lu: 'Luxemburg',
    },
    account_type: {
      buffer: 'Buffer',
      growth: 'Groei',
      essential: 'Essentieel',
      non_essential: 'Niet-essentieel',
      additional: 'Aanvullend',
    },
    validations: {
      required: 'Verplicht veld',
    },
    investment_plan: 'Investeringsplan',
    target_amount: 'Streefbedrag',
    investment_period: 'Investeringsperiode (jaren)',
    initial_deposit: 'Eerste storting',
    monthly_deposit: 'Maandelijkse storting',
    occupation: 'Beroep',
    make_a_choice: 'Maak een keuze',
    button_name: {
      month: '1 M',
      six_months: '6 M',
      year_to_date: 'YTD',
    },
    amount: 'Bedrag',
    low_performance: 'Lage prestatie',
    optimal_performance: 'Optimale prestatie',
    high_performance: 'Hoge prestatie',
    monthly_report: 'Maandrapportage',
    profitable_fund: 'Winstgevend Fonds',
    company_name: 'Bedrijfsnaam*',
    logo: 'Logo',
    cover_image: 'Omslagfoto',
    background_image: 'Achtergrond afbeelding',
    neutral_risk_bottom_value: 'Neutraal risicoprofiel bodemwaarde*',
    neutral_risk_top_value: 'Neutraal risicoprofiel topwaarde*',
    preview: 'Voorbeeld',
    save_your_changes: 'Sla uw wijzigingen op',
    primary_color: 'Primaire kleur',
    secondary_color: 'Secundaire kleur',
    background_color: 'Achtergrond kleur',
    text_color: 'Tekst kleur',
    drag_drop_files: 'Drag & Drop uw bestanden of',
    browse: 'Bladeren',
    id: 'ID',
    status: 'Status',
    role: 'Rol',
    add_user: 'Voeg gebruiker toe',
    edit_user: 'Bewerk gebruiker',
    cancel: 'Annuleren',
    save: 'Opslaan',
    active: 'Actief',
    dashboard: 'Dashboard',
    users: 'Gebruikers',
    configuration: 'Configuratie',
    log_out: 'Uitloggen',
    payment: 'Betaling',
    allow_automatic_debit: 'Automatische incasso',
    automatic_debit_description:
      '{{company}} mag automatisch elke maand afschrijven. Dit kan op elk moment worden gestopt.',
    contra_account: 'Tegenrekening',
    account_register: 'De bankrekening is geregistreerd onder de volgende naam',
    iban: 'IBAN',
    contra_account_description:
      'Waar kunnen wij het geld naar overmaken als u geld wilt ontrekken van het fonds?',
    account_purpose: 'Geef het doel van uw nieuwe rekening aan.',
    please_select_account_purpose:
      'Selecter alstublieft het doel voor de rekening hierboven.',
    payment_status: {
      success: 'De betaling was succesvol',
      failed_or_canceled_retry:
        'De betaling is geannuleerd, probeer het alstublieft opnieuw.',
      retry: 'Er ging iets fout, probeer het later opnieuw.',
    },
    occupation_status: {
      what_is_your_occupation_status: 'Wat is uw arbeidssituatie?',
      werknemer_in_bedrifsleven: 'Werknemer in bedrijfsleven',
      werknemer_semi_overheidsinstelling:
        'Werknemer (semi-)overheidsinstelling',
      partner_in_een_onderneming: 'Partner in een onderneming',
      bestuurder_neiet_grootaandeelhouder:
        'Bestuurder / neiet-grootaandeelhouder',
      ondernemer: "Ondernemer / ZZP'er",
      student: 'Student',
      gepensioneerd: 'Gepensioneerd',
      werkloos: 'Werkloos',
    },
    occupation_field: {
      occupation_field: 'Beroepsspecialisatie',
      bestuur_en_management: 'Bestuur en management',
      bouw_en_infrastructuur: 'Bouw en infrastructuur',
      financieel: 'Financieel',
      handel: 'Handel',
      ict_telecom_en_online: 'ICT, telecom en online',
      horeca: 'Horeca',
      industrie: 'Industrie',
      kunst_amusement_en_recreatie: 'Kunst, amusement en recreatie',
      onderwijs_en_overheid: 'Onderwijs en overheid',
      landbouw_bosbouw_en_visserij: 'Landbouw, bosbouw en visserij',
      vervoer_transport_en_logistiek: 'Vervoer, transport en logistiek',
      sport: 'Sport',
      vastgoed: 'Vastgoed',
      zakelijk_en_commercieel: 'Zakelijk en commercieel',
      zorg_en_welzijn: 'Zorg en welzijn',
      anders: 'Anders',
    },
    occupation_sector: {
      occupation_sector: 'Beroepssector',
      geen_van_onderstaande: 'Nee, geen van onderstaande',
      trustkantoren: 'Trustkantoren (Corporate Service Providers)',
      afvalbeheer_en_sanering: 'Afvalbeheer en sanering',
      offshore: 'Offshore',
      watervoorziening_en_riolering: 'Watervoorziening en riolering',
      betalingsdiensten_of_vergelijkbaar:
        'Betalingsdiensten of vergelijkbaar (PSP)',
      crowdfunding: 'Crowdfunding',
      handel_in_cryptovaluta_of_initial_coin_offering:
        'Handel in cryptovaluta of Initial Coin Offering (ICO)',
      verzekeringen: 'Verzekeringen',
    },
  },
}
