import React, { useMemo } from 'react'
import { FieldErrors, FieldValues } from 'react-hook-form'
import {
  faGraduationCap,
  faChartLine,
  faWallet,
  faGlobeEurope,
  faCreditCard,
} from '@fortawesome/free-solid-svg-icons'
import './bank-details.scss'
import RadioInput from '../../../components/radio-input'
import TextInput from '../../../components/text-input'
import { UseFormRegister } from 'react-hook-form'
import FormLayout from '../../../components/form-layout'
import styles from '../onboarding.module.scss'
import { useTranslation } from 'react-i18next'
import { requiredWithMessage } from '@/common/models/validation'

enum AccountType {
  Buffer = 'Buffer',
  Growth = 'Growth',
  Essential = 'Essential',
  NonEssential = 'NonEssential',
  Additional = 'Additional',
}

type Props = {
  register: UseFormRegister<FieldValues>
  errors: FieldErrors
  path: string
}
const BankDetails: React.FC<Props> = ({ register, errors, path }) => {
  const { t } = useTranslation()

  const navigation = useMemo(
    () => [
      {
        name: `${t('account_type.buffer')}`,
        value: AccountType.Buffer,
        icon: faWallet,
      },
      {
        name: `${t('account_type.growth')}`,
        value: AccountType.Growth,
        icon: faChartLine,
      },
      {
        name: `${t('account_type.essential')}`,
        value: AccountType.Essential,
        icon: faGraduationCap,
      },
      {
        name: `${t('account_type.non_essential')}`,
        value: AccountType.NonEssential,
        icon: faGlobeEurope,
      },
      {
        name: `${t('account_type.additional')}`,
        value: AccountType.Additional,
        icon: faCreditCard,
      },
    ],
    [t],
  )

  return (
    <FormLayout
      dataTestId="AccountDetails"
      title={t('account_details')}
      description={t('please_select')}
    >
      <RadioInput
        {...register(`${path}.bankAccount`, {
          ...requiredWithMessage,
          required: t('please_select_account_purpose'),
        })}
        label={t('account_purpose') + ' *'}
        errors={errors}
        navigation={navigation}
      />
      <TextInput
        wrapperClassName={styles.inputWrapperBasic}
        label={t('account_name') + ' *'}
        {...register(`${path}.accountName`, requiredWithMessage)}
        errors={errors}
      />
    </FormLayout>
  )
}

export default BankDetails
